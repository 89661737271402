import {
  List,
  TextField,
  DateField,
  ChipField,
  DatagridConfigurable,
  Filter,
  SearchInput,
  SelectArrayInput,
  FilterForm,
  usePermissions,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  ExportButton,
  DateTimeInput,
  NumberField,
  FunctionField,
} from "react-admin";
import { Stack } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const ListToolbar = () => <Stack direction="row"></Stack>;

const Referraltransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => <TopToolbar></TopToolbar>;
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Referred UserName"
        source="referredUserName"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Referral Transaction</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <FunctionField
            source="amount"
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />
          <UserNameHyperlink
            label="User Referred Name"
            source="referredUserName"
            to="userId"
          />

          <FunctionField
            label="Is SuspiciousUser"
            render={(record: any) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <img
                  src={
                    record.isSuspiciousUser
                      ? "/images/success.png"
                      : "/images/reject.png"
                  }
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                  }}
                />
                <span
                  style={{
                    fontSize: "12px",
                    color: record.isSuspiciousUser ? "green" : "red",
                  }}
                >
                  {record.isSuspiciousUser ? "true" : "false"}
                </span>
              </div>
            )}
          />
          <TextField source="os" label="Os " />
          <DateField source="createdAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Referraltransactions;
