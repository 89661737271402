import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, Typography, Button } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import StatsCard from "../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [revenueStats, setRevenueStats] = useState<any>({});
  const [gameDashboardStats, setGameDashboardStats] = useState<any>({});

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const [gameType, setGameType] = useState("all");
  const [selectedGameType, setSelectedGameType] = useState("quick");
  const [selectedChart, setSelectedChart] = useState("quick");
  const [selectedJoinTableView, setSelectedJoinTableView] = useState("quick");
  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#3AA6B9",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const handleGameTypeSelect = (selectedGameType: string) => {
    setGameType(selectedGameType);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType,
      };

      setLoading(true);

      const gameDashboardResponse = await axios.get(
        `${config.REST_ENDPOINT}/api/ludo-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGameDashboardStats(gameDashboardResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, gameType]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2
        style={{
          fontFamily: "Playfair Display",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        Ludo Game Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 2,
          bgcolor: "#00408B",
          color: "white",
          p: 1,
        }}
      >
        Game Statistics
      </Typography>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <Button
          variant={selectedChart === "quick" ? "contained" : "outlined"}
          onClick={() => setSelectedChart("quick")}
          sx={{
            bgcolor: selectedChart === "quick" ? "#00408B" : "transparent",
            color: selectedChart === "quick" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
          }}
        >
          Quick Game
        </Button>
        <Button
          variant={selectedChart === "classic" ? "contained" : "outlined"}
          onClick={() => setSelectedChart("classic")}
          sx={{
            bgcolor: selectedChart === "classic" ? "#00408B" : "transparent",
            color: selectedChart === "classic" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
          }}
        >
          Classic Game
        </Button>
        <Button
          variant={selectedChart === "furious4" ? "contained" : "outlined"}
          onClick={() => setSelectedChart("furious4")}
          sx={{
            bgcolor: selectedChart === "furious4" ? "#00408B" : "transparent",
            color: selectedChart === "furious4" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
          }}
        >
          Furious4 Game
        </Button>
      </div>

      {selectedChart === "quick" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "500px",
            width: "80%",
            margin: "auto",
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={[
                {
                  name: "Total Games",
                  value: gameDashboardStats["Total quick Games count"] ?? 0,
                  color: "#626F47",
                },
                {
                  name: "Total Amount",
                  value: gameDashboardStats["Total quick JoinFee count"] ?? 0,
                  displayValue: `₹${
                    gameDashboardStats["Total quick JoinFee count"] ?? 0
                  }`,
                  color: "#8EACCD",
                },
                {
                  name: "Commission",
                  value: gameDashboardStats["Total quick Commission"] ?? 0,
                  displayValue: `₹${
                    gameDashboardStats["Total quick Commission"] ?? 0
                  }`,
                  color: "#987070",
                },
                {
                  name: "Games Won",
                  value: gameDashboardStats["Quick Outcome Counts"]?.won ?? 0,
                  color: "#629584",
                },
                {
                  name: "Games Lost",
                  value: gameDashboardStats["Quick Outcome Counts"]?.lost ?? 0,
                  color: "#9B7EBD",
                },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={60} />
              <YAxis
                tickFormatter={(value) =>
                  value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
                }
              />
              <Tooltip
                formatter={(value, name, props) => {
                  return (
                    props.payload.displayValue || value.toLocaleString("en-IN")
                  );
                }}
              />
              <Legend />
              <Bar
  dataKey="value"
  shape={(props:any) => {
    const { x, y, width, height } = props;
    return (
      <rect
        x={x}
        y={y}
        width={40}  
        height={height}
        fill="#00408B80"
        stroke="#00408B"
        strokeWidth={3}
      />
    );
  }}
/>


            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {selectedChart === "classic" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "500px",
            width: "80%",
            margin: "auto",
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={[
                {
                  name: "Total Games",
                  value: gameDashboardStats["Total classic Games count"] ?? 0,
                  color: "#626F47",
                },
                {
                  name: "Total Amount",
                  value: gameDashboardStats["Total classic JoinFee count"] ?? 0,
                  displayValue: `₹${
                    gameDashboardStats["Total classic JoinFee count"] ?? 0
                  }`,
                  color: "#8EACCD",
                },
                {
                  name: "Commission",
                  value: gameDashboardStats["Total classic Commission"] ?? 0,
                  displayValue: `₹${
                    gameDashboardStats["Total classic Commission"] ?? 0
                  }`,
                  color: "#987070",
                },
                {
                  name: "Games Won",
                  value: gameDashboardStats["Classic Outcome Counts"]?.won ?? 0,
                  color: "#629584",
                },
                {
                  name: "Games Lost",

                  value:
                    gameDashboardStats["Classic Outcome Counts"]?.lost ?? 0,
                  color: "#9B7EBD",
                },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={60} />
              <YAxis
                tickFormatter={(value) =>
                  value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
                }
              />
              <Tooltip
                formatter={(value, name, props) => {
                  return (
                    props.payload.displayValue || value.toLocaleString("en-IN")
                  );
                }}
              />
              <Legend />
              <Bar
              dataKey="value"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
            />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {selectedChart === "furious4" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "500px",
            width: "80%",
            margin: "auto",
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={[
                {
                  name: "Total Games",
                  value: gameDashboardStats["Total furious4 Games count"] ?? 0,

                  color: "#6699CC",
                },
                {
                  name: "Total Amount",
                  value:
                    gameDashboardStats["Total furious4 JoinFee count"] ?? 0,
                  displayValue: `₹${
                    gameDashboardStats["Total furious4 JoinFee count"] ?? 0
                  }`,
                  color: "#009933",
                },
                {
                  name: "Commission",
                  value: gameDashboardStats["Total furious4 Commission"] ?? 0,
                  displayValue: `₹${
                    gameDashboardStats["Total furious4 Commission"] ?? 0
                  }`,
                  color: "#FF33BB",
                },
                {
                  name: "Games Won",
                  value:
                    gameDashboardStats["Furious4 Outcome Counts"]?.won ?? 0,
                  color: "#B033FF",
                },
                {
                  name: "Games Lost",
                  value:
                    gameDashboardStats["Furious4 Outcome Counts"]?.lost ?? 0,
                  color: "#FFC933",
                },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={60} />
              <YAxis
                tickFormatter={(value) =>
                  value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
                }
              />

              <Tooltip
                formatter={(value, name, props) => {
                  return (
                    props.payload.displayValue || value.toLocaleString("en-IN")
                  );
                }}
              />
              <Legend />
              <Bar
              dataKey="tables"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
              name="Number of Tables"
            />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 2,
          bgcolor: "#00408B",
          color: "white",
          p: 1,
        }}
      >
        Game Join Fee
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <Button
          variant={selectedJoinTableView === "quick" ? "contained" : "outlined"}
          onClick={() => setSelectedJoinTableView("quick")}
          sx={{
            bgcolor:
              selectedJoinTableView === "quick" ? "#00408B" : "transparent",
            color: selectedJoinTableView === "quick" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
          }}
        >
          Quick Game Tables
        </Button>
        <Button
          variant={
            selectedJoinTableView === "classic" ? "contained" : "outlined"
          }
          onClick={() => setSelectedJoinTableView("classic")}
          sx={{
            bgcolor:
              selectedJoinTableView === "classic" ? "#00408B" : "transparent",
            color: selectedJoinTableView === "classic" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
          }}
        >
          Classic Game Tables
        </Button>
        <Button
          variant={
            selectedJoinTableView === "furious4" ? "contained" : "outlined"
          }
          onClick={() => setSelectedJoinTableView("furious4")}
          sx={{
            bgcolor:
              selectedJoinTableView === "furious4" ? "#00408B" : "transparent",
            color: selectedJoinTableView === "furious4" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
          }}
        >
          Furious4 Game Tables
        </Button>
      </div>

      {/* Conditional rendering for join amount tables */}
      {selectedJoinTableView === "quick" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "500px",
            width: "80%",
            margin: "auto",
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={Object.entries(
                gameDashboardStats.quickJoinAmountAndTable || {}
              ).map(([key, value]) => ({
                joinAmount: `₹${key}`,
                tables: value,
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="joinAmount"
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                tickFormatter={(value) =>
                  value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
                }
              />
              <Tooltip formatter={(value) => value.toLocaleString("en-IN")} />
              <Legend />

              <Bar
              dataKey="tables"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
              name="Number of Tables"
            />            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {selectedJoinTableView === "classic" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "500px",
            width: "80%",
            margin: "auto",
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={Object.entries(
                gameDashboardStats.classicJoinAmountAndTable || {}
              ).map(([key, value]) => ({
                joinAmount: `₹${key}`,
                tables: value,
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="joinAmount"
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                tickFormatter={(value) =>
                  value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
                }
              />
              <Tooltip formatter={(value) => value.toLocaleString("en-IN")} />
              <Legend />
              <Bar
                 dataKey="tables"
                 fill="#00408B80"
                 stroke="#00408B"
                 strokeWidth={1}
                 barSize={30}
                 name="Number of Tables"
              />{" "}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {selectedJoinTableView === "furious4" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "500px",
            width: "80%",
            margin: "auto",
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={Object.entries(
                gameDashboardStats.furious4JoinAmountAndTable || {}
              ).map(([key, value]) => ({
                joinAmount: `₹${key}`,
                tables: value,
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="joinAmount"
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                tickFormatter={(value) =>
                  value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
                }
              />
              <Tooltip formatter={(value) => value.toLocaleString("en-IN")} />
              <Legend />

              <Bar
              dataKey="tables"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
              name="Number of Tables"
            />            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  );
};

export default GameplayDashboard;
