import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography, Box, Button } from "@mui/material";
import { DateRangePicker } from "rsuite";
import {
  MonetizationOn,
  CheckCircle,
  Cancel,
  HourglassEmpty,
  Restore,
  LocalOffer,
} from "@mui/icons-material";
import StatsCard from "../components/StatsCard";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";
import { CSVLink } from "react-csv";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface Transaction {
  mode: "convert" | "deposit" | "withdrawal";
  amount: string;
  settledAmount: string;
}
interface DashboardStats {
  totalDeposits: number;
  totalSuccessDeposits: number;
  totalFailedDeposits: number;
  totalPendingDeposits: number;
  totalWithdrawals: number;
  totalSuccessWithdrawals: number;
  totalFailedWithdrawals: number;
  totalPendingWithdrawals: number;
  totalRefunds: number;
  totalDepositAmount: number;
  totalSuccessDepositAmount: number;
  totalFailedDepositAmount: number;
  totalPendingDepositAmount: number;
  totalWithdrawalAmount: number;
  totalBonusAmount: number;
  totalSuccessWithdrawalAmount: number;
  totalFailedWithdrawalAmount: number;
  totalPendingWithdrawalAmount: number;
  totalRefundAmount: number;
  totalWithdrawalTdsAmount: number;
  totalGstDepositAmount: number;
  totalConvertedBonusAmount: number;
  totalConvertedAmount: number;
  [key: string]: number;
}

const PaymentDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [stats, setStats] = useState({
    totalDeposits: 0,
    totalSuccessDeposits: 0,
    totalFailedDeposits: 0,
    totalPendingDeposits: 0,
    totalWithdrawals: 0,
    totalSuccessWithdrawals: 0,
    totalFailedWithdrawals: 0,
    totalPendingWithdrawals: 0,
    totalRefunds: 0,
    totalDepositAmount: 0,
    totalSuccessDepositAmount: 0,
    totalFailedDepositAmount: 0,
    totalPendingDepositAmount: 0,
    totalWithdrawalAmount: 0,
    totalSuccessWithdrawalAmount: 0,
    totalFailedWithdrawalAmount: 0,
    totalPendingWithdrawalAmount: 0,
    totalRefundAmount: 0,
    totalWithdrawalTdsAmount: 0,
    totalGstDepositAmount: 0,
    totalCouponCount: 0,
    totalBonusAmount: 0,
    totalConvertedBonusAmount: 0,
    totalConvertedAmount: 0,
    totalSuccessWithdrawalAmountPlayStore: 0,
    totalFailedWithdrawalAmountPlayStore: 0,
    totalPendingWithdrawalAmountPlayStore: 0,
    totalSuccessWithdrawalsPlayStoreCount: 0,
    totalFailedWithdrawalsPlayStoreCount: 0,
    totalPendingWithdrawalsPlayStoreCount: 0,
    totalWithdrawalsPlayStoreCount: 0,
    totalWithdrawalPlayStoreAmount: 0,
  });

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);
  const [selectedView, setSelectedView] = useState("deposit");

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };
  const calculateTotals = (data: Transaction[]): Partial<DashboardStats> => {
    let totalConvertedBonusAmount = 0;
    let totalDepositGstAmount = 0;
    let totalWithdrawalTdsAmount = 0;
    let totalConvertedAmount = 0;

    data.forEach((transaction: Transaction) => {
      if (transaction.mode === "convert") {
        totalConvertedAmount += parseFloat(transaction.amount);
        totalConvertedBonusAmount +=
          parseFloat(transaction.settledAmount) -
          parseFloat(transaction.amount);
      } else if (transaction.mode === "deposit") {
        totalDepositGstAmount +=
          parseFloat(transaction.amount) -
          parseFloat(transaction.settledAmount);
      } else if (transaction.mode === "withdrawal") {
        totalWithdrawalTdsAmount +=
          parseFloat(transaction.amount) -
          parseFloat(transaction.settledAmount);
      }
    });

    return {
      totalConvertedBonusAmount,
      totalDepositGstAmount,
      totalWithdrawalTdsAmount,
      totalConvertedAmount,
    };
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);

      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
      };

      const url = `${config.REST_ENDPOINT}/api/payment/dashboard?key=${
        config.MAINTENANCE_BYPASS_KEY
      }&filter=${encodeURIComponent(JSON.stringify(filter))}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response && response.data) {
        const { data } = response;

        const calculatedTotals = calculateTotals(data.transactions || []);

        const newStats = {
          ...data,
          ...calculatedTotals,
          totalConvertedBonusAmount:
            data.convertedBonusAmount ||
            calculatedTotals.totalConvertedBonusAmount,
          totalDepositGstAmount:
            data.depositGstAmount || calculatedTotals.totalDepositGstAmount,
          totalWithdrawalTdsAmount:
            data.withdrawalTdsAmount ||
            calculatedTotals.totalWithdrawalTdsAmount,
          totalConvertedAmount:
            data.convertedAmount || calculatedTotals.totalConvertedAmount,
          totalGstDepositAmount: data.totalGstDepositAmount || 0,
        };

        setStats(newStats);
        setCsvData([newStats]);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      toast.error("Error fetching dashboard data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <h2
        style={{
          fontFamily: "Playfair Display",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        Payment Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        {["deposit", "withdrawal", "refund", "playstore"].map((view) => (
          <Button
            key={view}
            variant={selectedView === view ? "contained" : "outlined"}
            onClick={() => setSelectedView(view)}
            sx={{
              bgcolor: selectedView === view ? "#00408B" : "transparent",
              color: selectedView === view ? "white" : "#00408B",
              "&:hover": { bgcolor: "#00408B", color: "white" },
            }}
          >
            {view.charAt(0).toUpperCase() + view.slice(1)}
          </Button>
        ))}
      </div>

      {selectedView === "deposit" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "600px",
            width: "90%",
            margin: "auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              mb: 2,
              bgcolor: "#00408B",
              color: "white",
              p: 1,
            }}
          >
            Deposit Statistics
          </Typography>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={[
                {
                  name: "Total Deposits",
                  value: stats.totalDeposits,
                  color: "#889E73",
                },
                {
                  name: "Total Amount",
                  value: stats.totalDepositAmount,
                  color: "#889E73",
                },
                {
                  name: "Success Count",
                  value: stats.totalSuccessDeposits,
                  color: "#859F3D",
                },
                {
                  name: "Success Amount",
                  value: stats.totalSuccessDepositAmount,
                  color: "#859F3D",
                },
                {
                  name: "Failed Count",
                  value: stats.totalFailedDeposits,
                  color: "#A94A4A",
                },
                {
                  name: "Failed Amount",
                  value: stats.totalFailedDepositAmount,
                  color: "#A94A4A",
                },
                {
                  name: "Pending Count",
                  value: stats.totalPendingDeposits,
                  color: "#889E73",
                },
                {
                  name: "Pending Amount",
                  value: stats.totalPendingDepositAmount,
                  color: "#889E73",
                },
              ]}
              margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={80}
                interval={0}
                tick={{ fontSize: 17 }}
              />
              <YAxis width={50} />
              <Tooltip />
              <Legend wrapperStyle={{ paddingTop: 20 }} />
              <Bar
                dataKey="value"
                fill="#00408B40"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
      {selectedView === "withdrawal" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "600px",
            width: "90%",
            margin: "auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              mb: 2,
              bgcolor: "#00408B",
              color: "white",
              p: 1,
            }}
          >
            Withdrawal Statistics
          </Typography>

          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={[
                {
                  name: "Total Withdrawals",
                  value: stats.totalWithdrawals,
                  color: "#889E73",
                },
                {
                  name: "Total Amount",
                  value: stats.totalWithdrawalAmount,
                  color: "#889E73",
                },
                {
                  name: "Success Count",
                  value: stats.totalSuccessWithdrawals,
                  color: "#859F3D",
                },
                {
                  name: "Success Amount",
                  value: stats.totalSuccessWithdrawalAmount,
                  color: "#859F3D",
                },
                {
                  name: "Failed Count",
                  value: stats.totalFailedWithdrawals,
                  color: "#A94A4A",
                },
                {
                  name: "Failed Amount",
                  value: stats.totalFailedWithdrawalAmount,
                  color: "#A94A4A",
                },
                {
                  name: "Pending Count",
                  value: stats.totalPendingWithdrawals,
                  color: "#889E73",
                },
                {
                  name: "Pending Amount",
                  value: stats.totalPendingWithdrawalAmount,
                  color: "#889E73",
                },
              ]}
              margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={80}
                interval={0}
                tick={{ fontSize: 17 }}
              />
              <YAxis width={50} />
              <Tooltip />
              <Legend wrapperStyle={{ paddingTop: 20 }} />
              <Bar
                dataKey="value"
                fill="#00408B40"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
              />{" "}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {selectedView === "refund" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "600px",
            width: "90%",
            margin: "auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              mb: 2,
              bgcolor: "#00408B",
              color: "white",
              p: 1,
            }}
          >
            Refund Statistics
          </Typography>

          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={[
                {
                  name: "Total Refunds",
                  value: stats.totalRefunds,
                  color: "#889E73",
                },
                {
                  name: "Total Refund Amount",
                  value: stats.totalRefundAmount,
                  color: "#889E73",
                },
              ]}
              margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={80}
                interval={0}
                tick={{ fontSize: 17 }}
              />
              <YAxis width={50} />
              <Tooltip />
              <Legend wrapperStyle={{ paddingTop: 20 }} />
              <Bar
                dataKey="value"
                fill="#00408B40"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
              />{" "}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {selectedView === "playstore" && (
        <Box
          sx={{
            mt: 4,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "600px",
            width: "90%",
            margin: "auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              mb: 2,
              bgcolor: "#00408B",
              color: "white",
              p: 1,
            }}
          >
            Playstore Withdrawal Statistics
          </Typography>

          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={[
                {
                  name: "Total Withdrawals",
                  value: stats.totalWithdrawalsPlayStoreCount,
                  color: "#889E73",
                },
                {
                  name: "Total Amount",
                  value: stats.totalWithdrawalPlayStoreAmount,
                  color: "#889E73",
                },
                {
                  name: "Success Count",
                  value: stats.totalSuccessWithdrawalsPlayStoreCount,
                  color: "#859F3D",
                },
                {
                  name: "Success Amount",
                  value: stats.totalSuccessWithdrawalAmountPlayStore,
                  color: "#859F3D",
                },
                {
                  name: "Failed Count",
                  value: stats.totalFailedWithdrawalsPlayStoreCount,
                  color: "#A94A4A",
                },
                {
                  name: "Failed Amount",
                  value: stats.totalFailedWithdrawalAmountPlayStore,
                  color: "#A94A4A",
                },
                {
                  name: "Pending Count",
                  value: stats.totalPendingWithdrawalsPlayStoreCount,
                  color: "#889E73",
                },
                {
                  name: "Pending Amount",
                  value: stats.totalPendingWithdrawalAmountPlayStore,
                  color: "#889E73",
                },
              ]}
              margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={80}
                interval={0}
                tick={{ fontSize: 17 }}
              />
              <YAxis width={50} />
              <Tooltip />
              <Legend wrapperStyle={{ paddingTop: 20 }} />
              <Bar
                dataKey="value"
                fill="#00408B40"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
              />{" "}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  );
};

export default PaymentDashboard;
