import {
  AppBar,
  Logout,
  UserMenu,
  usePermissions,
  useUserMenu,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import MoodIcon from "@mui/icons-material/Mood";
const ConfigurationMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();

  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/configuration"
      onClick={onClose}
    >
      <ListItemIcon>
      <img 
          src="/images/lightanddark.png" 
          alt="Dark Mode" 
          style={{ width: '30px', height: '30px' }}
        />
      </ListItemIcon>
      <ListItemText>Dark Mode</ListItemText>
    </MenuItem>
  );
});

const AdminMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const { permissions } = usePermissions();
  const hasGameConfigPermission = !permissions.includes("developer");
  if (!hasGameConfigPermission) {
    return null;
  }

  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/profile"
      onClick={onClose}
    >
        <ListItemIcon>
        <img 
          src="/images/profile.png" 
          alt="Profile" 
          style={{ width: '30px', height: '30px' }}
        />
      </ListItemIcon>
      <ListItemText primary="Profile" />
    </MenuItem>
  );
});

export const GameConfigMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const { permissions } = usePermissions();
  const hasGameConfigPermission =
    permissions.includes("admin") || permissions.includes("developer");
  if (!hasGameConfigPermission) {
    return null;
  }

  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/api/game-config"
      onClick={onClose}
    >
       <ListItemIcon>
        <img 
          src="/images/confriguion.png" 
          alt="Configuration" 
          style={{ width: '30px', height: '30px' }}
        />
      </ListItemIcon>
      <ListItemText primary="Configuration" />
    </MenuItem>
  );
});
const CustomUserMenu = () => (
  <UserMenu icon={
    <img 
      src="/images/Fabzenright.png" 
      alt="Fabzen Logo" 
      height="30"
      style={{ 
        marginRight: '9px',
        objectFit: 'contain'
      }}
    />
  }>
    <AdminMenu />
    <ConfigurationMenu />
    <GameConfigMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm")
  );
  return (
    <AppBar
      elevation={1}
      sx={{ backgroundColor: "#00408B" }}
      userMenu={<CustomUserMenu />}
    >
      <>
        <img
          src="/images/FZ1.png"
          alt="Empire Games"
          height="40"
          style={{ marginRight: "10px" }}
        />
        <Typography sx={{ fontWeight: "bold", color: "#FFFFFF" }}>
          {" "}
          Fabzen Technologies
        </Typography>
        {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
      </>
    </AppBar>
  );
};


export default CustomAppBar;
