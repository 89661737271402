import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../../config";
import { getItemWithExpiry } from "../../utils";
import { toast } from "react-toastify";
import StatsCard from "../../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [revenueStats, setRevenueStats] = useState<any>({});
  const [gameDashboardStats, setGameDashboardStats] = useState<any>({});

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const [gameType, setGameType] = useState("all");
  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#009933",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const handleGameTypeSelect = (selectedGameType: string) => {
    setGameType(selectedGameType);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType,
      };

      setLoading(true);

      const gameDashboardResponse = await axios.get(
        `${config.REST_ENDPOINT}/api/mega-tournament/dashboard/stats?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGameDashboardStats(gameDashboardResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, gameType]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2
        style={{
          fontFamily: "Playfair Display",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        Mega Tournament Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      {/* Game Statistics Chart */}
      <Box
        sx={{
          mt: 4,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          height: "500px",
          width: "80%",
          margin: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mb: 2,
            bgcolor: "#00408B",
            color: "white",
            p: 1,
          }}
        >
          Game Statistics
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={[
              {
                name: "Total Games",
                value: gameDashboardStats["uniqueTournamentCount"] ?? 0,
                color: "#626F47",
              },
              {
                name: "Total Join Fee",
                value: gameDashboardStats["totalJoinFeeAmounts"] ?? 0,
                color: "#9B7EBD",
              },
              {
                name: "Total Commission",
                value: gameDashboardStats["totalCommission"] ?? 0,
                color: "#629584",
              },
            ]}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} textAnchor="end" height={60} />
            <YAxis
              tickFormatter={(value) =>
                value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
              }
            />
            <Tooltip formatter={(value) => value.toLocaleString("en-IN")} />
            <Legend />
            <Bar
              dataKey="value"
              shape={(props:any) => {
                const { x, y, width, height } = props;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={40}
                    height={height}
                    fill="#00408B80"
                    stroke="#00408B"
                    strokeWidth={3}
                  />
                );
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      {/* Join Amount Distribution Chart */}
      <Box sx={{ mt: 4, p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mb: 2,
            bgcolor: "#00408B",
            color: "white",
            p: 1,
          }}
        >
          Join Amount Distribution
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={Object.entries(
              gameDashboardStats.joinFeeAmountAndTables || {}
            ).map(([key, value]) => ({
              joinAmount: `₹${key}`,
              tables: value,
            }))}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="joinAmount" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="tables"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
              name="Number of Tables"
            />{" "}
          </BarChart>
        </ResponsiveContainer>
      </Box>
      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default GameplayDashboard;
