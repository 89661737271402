import querystring from "query-string";

import { fetchUtils, DataProvider } from "ra-core";
import config from "../config";
const { get, isEmpty } = require("lodash");

function convertToDotNotation(obj: any, parentKey = ""): object {
  const dotNotationObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (Array.isArray(val)) {
        dotNotationObj[newKey] = val;
      } else if (typeof val === "object") {
        const nestedObj = convertToDotNotation(val, newKey);
        Object.assign(dotNotationObj, nestedObj);
      } else {
        dotNotationObj[newKey] = val;
      }
    }
  }
  return dotNotationObj;
}

const providerFunction = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage = 10 } = params.pagination;
    const { field = "createdAt", order = -1 } = params.sort;

    const query = {
      key: config.MAINTENANCE_BYPASS_KEY,
      filter: JSON.stringify(convertToDotNotation(params.filter)),
      skip: (page - 1) * perPage,
      count: perPage,
      sortBy: field,
      sortDir: order === "ASC" ? 1 : -1,
    };

    const url = `${apiUrl}/${resource}?${querystring.stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (isEmpty(get(json, "items"))) {
        throw new Error("No Data Found");
      }
      const { items, meta } = json;
      // React-Admin Requires Unique Value in 'id' key. If that id key is not available assigning _id value to id
      const data = items.map((item: { id: any; _id: string }) => {
        const unqiueIdAddedObj = { ...item };
        if (!item.id) {
          unqiueIdAddedObj["id"] = item?._id;
        }
        return unqiueIdAddedObj;
      });

      return {
        data,
        total: get(meta, "totalCount", 0),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(
      `${apiUrl}/${resource}/${params.id}?key=${config.MAINTENANCE_BYPASS_KEY}`
    ).then(({ json }) => ({
      data: { ...json, id: json._id ? json._id : 0 },
    })),

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${querystring.stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${querystring.stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: 0,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, ...json, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});
export default providerFunction;
