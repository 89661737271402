import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  NumberField,
  FunctionField,
  FilterForm,
  DateTimeInput,
  SelectArrayInput,
  FilterButton,
} from "react-admin";
import Big from "big.js";
import UserNameHyperlink from "../../../components/UserNameHyperlink";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { Typography } from "@mui/material";

const SnlGameHistory = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
      key="outcome"
      label="Filter by Outcome"
      source="outcome"
      choices={[
        { id: "won", name: "Won" },
        { id: "lost", name: "Lost" },
      ]}
      sx={{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#00408B",
          },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#00408B",
        },
        "& .MuiCheckbox-root.Mui-checked": {
          color: "#00408B",
        },
      }}
    />,
    <DateTimeInput key="startDate" source="startDate" alwaysOn />,
    <DateTimeInput key="endDate" source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search User Name"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Join Amount"
        source="joinFee"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Table ID"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const valueFormatter = (value: any) => {
    if (typeof value !== "undefined" && value !== null && !isNaN(value)) {
      const formattedValue = new Big(value);
      return formattedValue.toFixed(2);
    }
    return "";
  };

  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>
        SnakeAndLadder Game Histories
      </h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink source="userName" to="userId" label="User Name" />

          <TextField source="tableId" label="Table Id" />

          <FunctionField
            label="Join Fee"
            render={(record: any) => `₹${record.joinFee}`}
          />

          <TextField source="roomSize" label="Room Size" />
          <FunctionField
            source="winLoseAmount"
            label="Win/Loss Amount"
            render={(record: any) => `₹${record.winLoseAmount}`}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Add your content here */}
          </div>

          <TextField source="totalScore" label="Total Score" />
          <FunctionField
            label="Outcome"
            render={(record: any) => {
              const outcomeConfig = {
                won: {
                  icon: "/images/won.png",
                  label: "Won",
                },
                lost: {
                  icon: "/images/loss.png",
                  label: "Lost",
                },
              };

              const config =
                outcomeConfig[record.outcome as keyof typeof outcomeConfig];

              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <img
                    src={config.icon}
                    alt={config.label}
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                  />
                  <span style={{ fontSize: "12px" }}>{config.label}</span>
                </div>
              );
            }}
          />
          <DateField source="startedAt" showTime label="Started at" />

          <DateField source="updatedAt" showTime label="Updated at" />
        </DatagridConfigurable>
      </List>
    </div>
  );
};
export default SnlGameHistory;
