import React, { useState } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
  Button as RaButton,
} from "react-admin";
import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { callRestApi } from "../utils/callRestApi";

const Deposits = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
        { id: "refund", name: "Refund" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by Gateway"
      source="gateway"
      choices={[
        { id: "juspay", name: "Juspay" },
        { id: "Cashfree", name: "Cashfree" },
        { id: "ebanx", name: "Ebanx" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by Payment Method"
      source="paymentMethod"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "Card", name: "Card" },
        { id: "NetBanking", name: "Net Banking" },
        { id: "amex", name: "Amex" },
      ]}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const handleDownloadInvoice = async (orderId: string) => {
    try {
      const endpoint = "/payment/generate-invoice";
      const invoiceUrl = await callRestApi(endpoint, "POST", {
        orderId: orderId,
      });
      if (
        invoiceUrl &&
        invoiceUrl.data &&
        typeof invoiceUrl.data === "string"
      ) {
        const downloadLink = document.createElement("a");
        downloadLink.href = invoiceUrl.data;
        downloadLink.download = `invoice_${orderId}.pdf`;
        downloadLink.click();
        downloadLink.remove();
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  const DepositsActions = ({ record }: { record: any }) => {
    console.log("DepositsActions rendered:", record);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const couponDetailsExist =
      record.couponDetails && Object.keys(record.couponDetails).length > 0;
    console.log({ couponDetailsExist });

    return (
      <Box>
        <Stack spacing={2} direction="row">
          {record.status === "success" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/download.png`}
                alt="Download Invoice"
                style={{
                  width: "35px",
                  height: "35px",
                  cursor: "pointer",
                }}
                onClick={() => handleDownloadInvoice(record.orderId)}
              />
              <span style={{ fontSize: "12px" }}> Invoice</span>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/couponbutton.png`}
              alt="View Coupon"
              style={{
                width: "35px",
                height: "35px",
                cursor: "pointer",
                opacity: couponDetailsExist ? 1 : 0.5,
              }}
              onClick={handleClickOpen}
            />
            <span style={{ fontSize: "12px" }}>Coupon</span>
          </div>
        </Stack>

        {/* Keep existing Dialog component */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Coupon Details</DialogTitle>
          <DialogContent>
            {couponDetailsExist ? (
              <>
                <Typography>
                  Promo Code: {record.couponDetails.promoCode}
                </Typography>
                <Typography>
                  Bonus Amount: {record.couponDetails.bonusAmount}
                </Typography>
                <Typography>
                  Bonus Type: {record.couponDetails.bonusType}
                </Typography>
                <Typography>Wallet: {record.couponDetails.wallet}</Typography>
              </>
            ) : (
              <Typography>
                No coupon details available for this record.
              </Typography>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Deposits</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <FunctionField
            source="amount"
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />{" "}
          <TextField source="gateway" />
          <TextField source="paymentMethod" />
          <FunctionField
            label="Status"
            render={(record: any) => {
              const statusConfig = {
                success: {
                  icon: "/images/success.png",
                  label: "Success",
                },
                failed: {
                  icon: "/images/failed.png",
                  label: "Failed",
                },
                pending: {
                  icon: "/images/pending.png",
                  label: "Pending",
                },
                refund: {
                  icon: "/images/refund.png",
                  label: "Refund",
                },
              };
              console.log("status field");
              console.log("record:", record);

              const config =
                statusConfig[record.status as keyof typeof statusConfig];
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={config.icon}
                    alt={config.label}
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                  />
                  <span style={{ fontSize: "12px" }}>{config.label}</span>
                </div>
              );
            }}
          />
          <FunctionField
            label="Actions"
            render={(record: any) => {
              console.log("FunctionField record:", record);
              return <DepositsActions record={record} />;
            }}
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Deposits;
