import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography, Card, Button } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [revenueStats, setRevenueStats] = useState<any>({});
  const [viewType, setViewType] = useState('count');

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);
  const [gameType, setGameType] = useState("all");

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType,
      };

      setLoading(true);

      const revenueResponse = await axios.get(
        `${config.REST_ENDPOINT}/api/payment/revenue-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setRevenueStats(revenueResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange, gameType]);

  const chartData = viewType === 'count' ? [
    {
      name: "Signup Users Count",
      value: revenueStats.totalSignUpUsers ?? 0,
      isCurrency: false,
    },
    {
      name: "Referral Users Count",
      value: revenueStats.totalReferralUsersCount ?? 0,
      isCurrency: false,
    }
  ] : [
    {
      name: "Signup Bonus Amount",
      value: revenueStats.totalSignUpBonusAmount ?? 0,
      isCurrency: true,
    },
    {
      name: "Referral Bonus Amount",
      value: revenueStats.totalReferralBonusAmount ?? 0,
      isCurrency: true,
    },
    {
      name: "Total Game Commission",
      value: revenueStats.totalGameCommission ?? 0,
      isCurrency: true,
    },
    {
      name: "Total TDS Amount",
      value: revenueStats.withdrawalTdsAmount ?? 0,
      isCurrency: true,
    },
    {
      name: "Total GST Amount",
      value: revenueStats.depositGstAmount ?? 0,
      isCurrency: true,
    },
    {
      name: "Total Coupon Bonus Amount",
      value: revenueStats.couponBonusAmount ?? 0,
      isCurrency: true,
    },
    {
      name: "Total Converted Bonus Amount",
      value: revenueStats.convertedBonusAmount ?? 0,
      isCurrency: true,
    },
    {
      name: "Total Profit/Loss",
      value: revenueStats.totalProfitAndLoss ?? 0,
      isCurrency: true,
    }
  ];

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", margin: "auto" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2
        style={{
          fontFamily: "Playfair Display",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        Revenue Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
      <Button
          variant={viewType === 'amount' ? 'contained' : 'outlined'} 
          onClick={() => setViewType('amount')}
          sx={{
            backgroundColor: viewType === 'amount' ? '#00408B' : 'white',
            color: viewType === 'amount' ? 'white' : '#00408B',
            '&:hover': {
              backgroundColor: viewType === 'amount' ? '#003070' : '#f5f5f5'
            }
          }}
        >
          Show Amounts
        </Button>
        <Button 
          variant={viewType === 'count' ? 'contained' : 'outlined'}
          onClick={() => setViewType('count')}
          sx={{ 
            backgroundColor: viewType === 'count' ? '#00408B' : 'white',
            color: viewType === 'count' ? 'white' : '#00408B',
            '&:hover': {
              backgroundColor: viewType === 'count' ? '#003070' : '#f5f5f5'
            }
          }}
        >
          Show Counts
        </Button>
        
      </Box>

      <Card sx={{ p: 3 }}>
        <div style={{ height: 600 }}>
          <ResponsiveContainer>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={100}
                interval={0}
              />
              <YAxis />
              <Tooltip
                formatter={(value, name) => [
                  chartData.find((item) => item.name === name)?.isCurrency
                    ? `₹${Number(value).toFixed(2)}`
                    : value,
                  name,
                ]}
              />

              <Bar
                dataKey="value"
                fill="#00408B80"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill="#00408B80"
                    stroke="#00408B"
                  />
                ))}
                <LabelList
                  dataKey={(data: any) =>
                    data.isCurrency ? `₹${data.value.toFixed(2)}` : data.value
                  }
                  position="top"
                  style={{ fontWeight: "bold" }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </>
  );
};

export default GameplayDashboard;
