import { defaultTheme } from 'react-admin';

export const darkTheme = {
    palette: {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    sidebar: {
        width: 320,

    },
    components: {
        ...defaultTheme.components,
        // RaSidebar: {
        //     styleOverrides: {
        //         root: {
        //             background: "linear-gradient(#ffc0cb, #AA336A 0%, #ffc0cb 100%)",
        //             borderRight: '1px solid #E0E0E3',
        //         }
        //     }
            
        // },
        
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // background: 'linear-gradient(#ffc0cb, #AA336A 0%, #ffc0cb 100%)',
                    borderLeft: '3px solid #000',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '3px solid #90caf9',
                        // background: 'linear-gradient(#ffc0cb, #AA336A 0%, #ffc0cb 100%)',
                    },
                },
                '& .MuiTypography-root': {
                    fontWeight: 'bold',
                    color: '#ffffff'
                  }
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: '#ffffffb3',
                    backgroundColor: '#616161e6',
                },
            },
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#4f3cc9',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
        mode: 'light' as 'light',
    },
    shape: {
        borderRadius: 10,
    },
    sidebar: {
        width: 320,

    },
    components: {
        ...defaultTheme.components,
        RaLayout: {
            styleOverrides: {
              root: {
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#ffffff',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#000000',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#333333',
                                }
              }
            }
          },
          
          RaMenuItemLink: {
            styleOverrides: {
              root: {
                backgroundColor: "#ffffff",    
                  borderLeft: '3px solid #002d62',
                '&.RaMenuItemLink-active': {
                  borderLeft: '3px solid #4f95ff',
                  backgroundColor: "#00408B", // Background color for active item
                  '& .MuiTypography-root': {
                      color: '#ffffff', // White text for active item
                  },
                  '& .MuiListItemIcon-root': {
                      color: '#ffffff', // White icon for active item
                  },
                },
      
                },
            },
            '& .MuiTypography-root': {
                fontWeight: 'bold',
                color: '#000000' 
              }
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none',
                },
                root: {
                    border: '1px solid #e0e0e3',
                    backgroundClip: 'padding-box',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: '#808080',
                    backgroundColor: '#fff',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#f5f5f5',
                },
                barColorPrimary: {
                    backgroundColor: '#d7d7d7',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child td': { border: 0 },
                },
            },
        },
    },
};