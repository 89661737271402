import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import StatsCard from "../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";
import { Android } from "@mui/icons-material";

const AppVersionsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [appCodeCounts, setAppCodeCounts] = useState<any[]>([]);
  const [appVersionCounts, setAppVersionCounts] = useState<any[]>([]);
  const [platformCounts, setPlatformCounts] = useState<any[]>([]);
  const currentDate = new Date();
  const defaultStartDate = new Date("2024-01-01T00:00:00.000Z");
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    defaultStartDate,
    currentDate,
  ]);
  // Add state for selected view
  const [selectedView, setSelectedView] = useState("versions");
  const CustomBar = (props: any) => {
    const { x, y, width, height, value, platform } = props;

    const getImage = (platform: string | null) => {
      if (!platform) return "";

      switch (platform.toLowerCase()) {
        case "website":
          return "/images/website.png";
        case "playstore":
          return "/images/playstore.png";
        case "appstore":
          return "/images/appstore.png";
        default:
          return "";
      }
    };
    return (
      <g>
        <rect x={x} y={y} width={width} height={height} fill="#ffc658" />
        <image
          x={x + width / 2 - 15}
          y={y - 30}
          width="30"
          height="30"
          href={getImage(platform)}
          style={{ objectFit: "contain" }}
        />
      </g>
    );
  };

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    const getImage = (platform: string) => {
      if (!platform) return "";
      switch (platform.toLowerCase()) {
        case "website":
          return "/images/website.png";
        case "playstore":
          return "/images/playstore.png";
        case "appstore":
          return "/images/appstore.png";
        default:
          return "";
      }
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <image
          x={-15}
          y={10}
          width="30"
          height="30"
          href={getImage(payload.value)}
          style={{ objectFit: "contain" }}
        />
        <text x={0} y={50} dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };

  // Update the Platform Distribution chart XAxis
  <XAxis dataKey="platform" height={90} tick={<CustomXAxisTick />} />;

  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#009933",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = { startDate, endDate };
      setLoading(true);

      const response = await axios.get(
        `${config.REST_ENDPOINT}/api/users/appVersions/dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const { appCodeCounts, appVersionCounts, platformCounts } = response.data;
      setAppCodeCounts(appCodeCounts || []);
      setAppVersionCounts(appVersionCounts || []);
      setPlatformCounts(platformCounts || []);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2
        style={{
          fontFamily: "Playfair Display",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        App Version Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <Box
          component="button"
          onClick={() => setSelectedView("versions")}
          sx={{
            bgcolor: selectedView === "versions" ? "#00408B" : "transparent",
            color: selectedView === "versions" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
            border: "1px solid #00408B",
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          App Versions
        </Box>
        <Box
          component="button"
          onClick={() => setSelectedView("codes")}
          sx={{
            bgcolor: selectedView === "codes" ? "#00408B" : "transparent",
            color: selectedView === "codes" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
            border: "1px solid #00408B",
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          App Codes
        </Box>
        <Box
          component="button"
          onClick={() => setSelectedView("platform")}
          sx={{
            bgcolor: selectedView === "platform" ? "#00408B" : "transparent",
            color: selectedView === "platform" ? "white" : "#00408B",
            "&:hover": { bgcolor: "#00408B", color: "white" },
            border: "1px solid #00408B",
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Platform
        </Box>
      </div>

      {/* Conditional rendering for charts */}
      {selectedView === "versions" && (
        <div style={{ marginTop: "40px" }}>
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              marginBottom: 20,
              backgroundColor: "#00408B",
              color: "white",
            }}
          >
            App Versions Distribution
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={appVersionCounts}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="appVersion" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="count"
                fill="#00408B80"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
                name="Number of Users"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {selectedView === "codes" && (
        <div style={{ marginTop: "40px" }}>
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              marginBottom: 20,
              backgroundColor: "#00408B",
              color: "white",
            }}
          >
            App Codes Distribution
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={appCodeCounts}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="appCode" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="count"
                fill="#00408B80"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
                name="Number of Users"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {selectedView === "platform" && (
        <div style={{ marginTop: "40px" }}>
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              marginBottom: 20,
              backgroundColor: "#00408B",
              color: "white",
            }}
          >
            Platform Distribution
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={platformCounts}
              margin={{ top: 40, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="platform" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="count"
                fill="#00408B80"
                stroke="#00408B"
                strokeWidth={3}
                barSize={40}
                name="Number of Users"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default AppVersionsDashboard;
