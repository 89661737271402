import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  DeleteForever,
  ModeEdit,
  RemoveRedEye,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Confirm,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateTimeInput,
  ExportButton,
  Filter,
  FilterButton,
  FilterForm,
  FilterProps,
  FunctionField,
  List,
  SearchInput,
  SelectArrayInput,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  usePermissions,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Box, Chip, Stack } from "@mui/material";

import WinningsPrizeModal from "../tournaments/WinningsPrizeModal";
import { callRestApi } from "../../utils/callRestApi";
import MegaTournamentWinningsPrizeModal from "../tournaments/MegaTournamentWinningsPrizeModal";

const MegaTournaments = (props: any) => {
  const [winningPrizeModal, setWinningPrizeModal] = useState(false);
  const [winningPrize, setWinningPrize] = useState();
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");
  const [loading, setLoading] = useState(false);
  const [isActiveChangeModalOpen, setIsActiveChangeModalOpen] = useState(false);
  const [isActiveChangeRecord, setIsActiveChangeRecord] = useState();

  const refresh = useRefresh();
  const HeaderFilters = (props: any) => (
    <Box display="flex" alignItems="center">
      <Filter {...props}>
        <SearchInput
          placeholder="Search By Tournament Name"
          source="name"
          resettable
          alwaysOn
        />
      </Filter>
      <TournamentIdFilter {...props} />
    </Box>
  );
  const TournamentIdFilter = (props: FilterProps) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search By Tournament ID"
        source="_id"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const QuickFilter = ({ label }: any) => {
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
  };

  const toolbarFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "live", name: "Live" },
        { id: "full", name: "Full" },
        { id: "completed", name: "Completed" },
        { id: "canceled", name: "Canceled" },
        { id: "closed", name: "Closed" },
      ]}
    />,
    <QuickFilter
      source="useSamePawnPositions"
      label="Repeatable Tournaments"
      defaultValue={true}
    />,
    <QuickFilter
      source="useSamePawnPositions"
      label="Same Pawn"
      defaultValue={true}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={toolbarFilters} />
    </Stack>
  );

  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={toolbarFilters} />
    </TopToolbar>
  );

  const handleDeleteTournament = async (recordId: string) => {
    try {
      setLoading(true);
      const { status } = (await callRestApi(
        `/tournaments/${recordId}`,
        "DELETE"
      )) as AxiosResponse;
      if (status === 200) {
        toast("Tournament Deleted Successfully", {
          type: "success",
        });
      }
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setDeleteConfirmModalOpen(false);
    setLoading(false);
    refresh();
  };

  const handleIsActiveChange = async (record: any) => {
    try {
      setLoading(true);
      const { status } = (await callRestApi(
        `/ludo/tournament/${record.id}/cancel`,
        "POST",
        {},
        `isActive=${!record.isActive}`
      )) as AxiosResponse;
      if (status === 200) {
        toast(
          `Tournament ${
            record.isActive ? "Deactivated" : "Activated"
          } Successfully`,
          {
            type: "success",
          }
        );
      }
      setLoading(false);
      setIsActiveChangeModalOpen(false);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setDeleteConfirmModalOpen(false);
    setLoading(false);
    refresh();
  };
  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>
        Mega Tournaments History{" "}
      </h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<HeaderFilters />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <FunctionField
            label="Tournament Name"
            textAlign="center"
            render={(record: any) => {
              return (
                <Link to={`/ludo/mega-tournament/${record?.id}/show`}>
                  {record.name}
                </Link>
              );
            }}
          />
          <FunctionField
            label="Tournament Id"
            textAlign="center"
            render={(record: any) => {
              return (
                <Link to={`/ludo/mega-tournament/${record?.id}/show`}>
                  {record.id}
                </Link>
              );
            }}
          />
          <TextField source="alias" label="Alias" />
          <DateField source="createdAt" label="Created At" showTime />
          <DateField source="endAt" label="Ends At" showTime />

          <FunctionField
            label="Winning Prizes"
            textAlign="center"
            render={(record: any) => {
              return (
                <RemoveRedEye
                  titleAccess="View Winning Prizes"
                  color="primary"
                  onClick={() => {
                    setWinningPrizeModal(true);
                    setWinningPrize(() => {
                      return record;
                    });
                  }}
                  fontSize="large"
                  sx={{ "&:hover": { color: "green" }, cursor: "pointer" }}
                />
              );
            }}
          />
          <FunctionField
            label="Join Fees"
            textAlign="center"
            render={(record: any) => {
              return "₹" + record.joinFee;
            }}
          />
          <FunctionField
            label="Commission"
            textAlign="center"
            render={(record: any) => {
              const commission = (
                (record.joinFee * record.enteredUserCount -
                record.totalWinAmount) || 0
              ).toFixed(2);
              return "₹" + commission;
            }}
          />
          <FunctionField
  label="Status"
  textAlign="center"
  render={(record: any) => {
    const iconStyle = {
      width: '35px',
      height: '35px',
      marginRight: '8px',
      verticalAlign: 'middle'
    };

    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    const textStyle = {
      margin: 0,
      fontSize: '14px'
    };

    switch (record.status) {
      case 'completed':
        return (
          <div style={containerStyle}>
            <img src="/images/success.png" alt="Completed" style={iconStyle} />
            <span style={textStyle}></span>
          </div>
        );
      case 'canceled':
        return (
          <div style={containerStyle}>
            <img src="/images/reject.png" alt="Canceled" style={iconStyle} />
            <span style={textStyle}></span>
          </div>
        );
      default:
        return <span style={textStyle}>{record.status}</span>;
    }
  }}
/>

          <BooleanField source="isRepeatable" label="Repeatable" />
          <BooleanField source="useSamePawnPositions" label="Same Pawn" />
        </DatagridConfigurable>
      </List>

      {winningPrizeModal && (
        <MegaTournamentWinningsPrizeModal
          open={winningPrizeModal}
          close={setWinningPrizeModal}
          data={winningPrize}
        />
      )}

      {deleteConfirmModalOpen && (
        <Confirm
          isOpen={deleteConfirmModalOpen}
          title={`Please Confirm the Action`}
          content="Are you sure want to delete tournament?"
          onConfirm={() => handleDeleteTournament(deleteRecord)}
          onClose={() => setDeleteConfirmModalOpen(false)}
          loading={loading}
        />
      )}

      {isActiveChangeModalOpen && (
        <Confirm
          isOpen={isActiveChangeModalOpen}
          title={`Please Confirm the Action`}
          content={`Are you sure want to ${
            get(isActiveChangeRecord, "isActive", "take action on this")
              ? "de-activate"
              : "activate"
          } tournament?`}
          onConfirm={() => handleIsActiveChange(isActiveChangeRecord)}
          onClose={() => setIsActiveChangeModalOpen(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default MegaTournaments;
