import React, { useState } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FunctionField,
  usePermissions,
  DatagridConfigurable,
  SelectArrayInput,
  FilterForm,
  FilterButton,
  useDataProvider,
  useRefresh,
  Button,
  useNotify,
} from "react-admin";
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { toast } from "react-toastify";
import { getItemWithExpiry } from "../../utils/sessionData";
import axios from "axios";

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Wallet"
    source="wallet"
    choices={[
      { id: "main", name: "Main Wallet" },
      { id: "win", name: "Winning Wallet" },
      { id: "bonus", name: "Bonus Wallet" },
    ]}
  />,

  <SelectArrayInput
    label="Filter by Cashback"
    source="bonusType"
    choices={[
      { id: "percentage", name: "Percentage" },
      { id: "rupees", name: "Rupees" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const formatAmount = (value: string, bonusType?: string) => {
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) {
    if (bonusType === "percentage") {
      return `${numberValue.toFixed(2)}%`;
    }
    return `₹${numberValue.toFixed(2)}`;
  }
  return bonusType === "percentage" ? "0.00%" : "₹0.00";
};

const DeleteButton = ({ record }: { record: any }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const openDeleteConfirmation = () => setDeleteConfirmation(true);
  const closeDeleteConfirmation = () => setDeleteConfirmation(false);

  return (
    <>
      <Button
        label={record.isDeleted ? "Restore" : "Delete"}
        onClick={openDeleteConfirmation}
      >
        <DeleteForever />
      </Button>
      <Dialog open={deleteConfirmation} onClose={closeDeleteConfirmation}>
        <DialogTitle>
          {record.isDeleted ? "Confirm Restoration" : "Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {record.isDeleted
              ? "Are you sure you want to restore this coupon?"
              : "Are you sure you want to delete this coupon? This action can be undone."}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const CouponHistory = (props: any) => {
  const { permissions } = usePermissions();

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Promo Code"
        source="promoCode"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Bonus Amount"
        source="bonusAmount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Coupon Dashboard</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        {permissions.includes("admin") && (
          <DatagridConfigurable bulkActionButtons={false}>
            <TextField source="promoCode" label="Promo Code" />
            <TextField source="name" label="Coupon Title" />
            <FunctionField
              source="minAmount"
              label="Min Amount"
              render={(record: any) => formatAmount(record.minAmount)}
            />
            <FunctionField
              source="maxAmount"
              label="Max Amount"
              render={(record: any) => formatAmount(record.maxAmount)}
            />
            <FunctionField
              source="bonusAmount"
              label="Bonus Amount"
              render={(record: any) =>
                formatAmount(record.bonusAmount, record.bonusType)
              }
            />
            ;
            <TextField source="partner" label="Partner" />
            <TextField source="bonusType" label="Bonus Type" />
            <FunctionField
              label="Is Deleted"
              render={(record: any) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      record.isDeleted
                        ? "/images/success.png"
                        : "/images/reject.png"
                    }
                    alt={record.isDeleted.toString()}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "contain",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      color: record.isDeleted ? "green" : "red",
                    }}
                  >
                    {record.isDeleted.toString()}
                  </span>
                </div>
              )}
            />
            <TextField source="wallet" label="Wallet" />
            <FunctionField
              label="Is Only For First Deposit"
              render={(record: any) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      record.isOnlyForFirstDeposit
                        ? "/images/success.png"
                        : "/images/reject.png"
                    }
                    alt={record.isOnlyForFirstDeposit.toString()}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "contain",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      color: record.isOnlyForFirstDeposit ? "green" : "red",
                    }}
                  >
                    {record.isOnlyForFirstDeposit.toString()}
                  </span>
                </div>
              )}
            />
            <FunctionField
              label="Is For All"
              render={(record: any) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      record.isForAll
                        ? "/images/success.png"
                        : "/images/reject.png"
                    }
                    alt={record.isForAll.toString()}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "contain",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      color: record.isForAll ? "green" : "red",
                    }}
                  >
                    {record.isForAll.toString()}
                  </span>
                </div>
              )}
            />
            <DateField source="expireAt" showTime label="Coupon Expire Date" />
          </DatagridConfigurable>
        )}
      </List>
    </>
  );
};

export default CouponHistory;
