import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FilterForm,
  usePermissions,
  DateTimeInput,
  useListContext,
  FunctionField,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { useState } from "react";

const ReferralRecords = (props: object) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [refferalCode, setrefferalCode] = useState<any>();

  const { data } = useListContext();

  const { permissions } = usePermissions();
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );
  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Referral Code"
        source="referralCode"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Referred UserName"
        source="referredUserName"
        resettable
        alwaysOn
      />
    </Filter>
  );
  const ListActions = () => (
    <TopToolbar>
    </TopToolbar>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Referrals Records</h2>

      <div
        style={{
          fontWeight: "bold",
          color: "black",
          marginTop: "5px",
          paddingLeft: "0%",
          whiteSpace: "pre",
        }}
      >
        {" "}
        Most Used Referral Code: {refferalCode?.referralCode}
        {"      "}
        Count: {refferalCode?.totalNum}
      </div>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="referralCode" label="Referral Code" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="referredUserName" label="Referred Username" />
          <TextField source="totalMatches" label="Total Matches" />
          <FunctionField
            label="Amount"
            render={(record: any) => {
              if ("Maximum" in record) {
                setrefferalCode(record.Maximum);
              }
              return record.amount;
            }}
          />
          <DateField source="createdAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default ReferralRecords;
