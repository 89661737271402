import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { callRestApi } from "../utils/callRestApi";
import { toast } from "react-toastify";

/**
 * The `WalletTransactionModal` component is a React functional component that renders a dialog modal for adding money to a user's wallet.
 * 
 * The component takes in several props:
 * - `open`: a boolean indicating whether the modal should be open or not
 * - `close`: a function to close the modal
 * - `userId`: the ID of the user whose wallet is being updated
 * - `title`: the title of the modal
 * - `transaction`: the type of transaction (either "credit" or "debit")
 * - `notify`: a function to notify the user of the transaction status
 * 
 * The component renders a form with various input fields, including the amount to be added, the order or table ID, the game, and the wallet to be used. The form also includes validation to ensure that required fields are filled out.
 * 
 * When the "Add" button is clicked, the `onSave` function is called, which sends a POST request to the `/transactions/admin-refund` endpoint to update the user's wallet. If the request is successful, a success message is displayed using the `toast` library, the modal is closed, and the `notify` function is called.
 */
const WalletTransactionModal = (props: any) => {
  const { open, close, userId, title, transaction, notify } = props;
  let isCredit = true;
  if (transaction === "credit") {
    isCredit = true;
  } else {
    isCredit = false;
  }
  const [formData, setFormData] = useState({
    amount: "",
    selectedField: "amount",
    orderId: "",
    tableId: "",
    wallet: "",
    game: "",
    credit: isCredit,
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setMessage("");
    if (!formData.wallet) {
      setMessage("Wallet is a mandatory field");
      return;
    }

    if (formData.selectedField === "orderId" && !formData.orderId) {
      setMessage("Order ID is mandatory when selected.");
      return;
    }
    if (formData.selectedField === "tableId" && !formData.tableId) {
      setMessage("Table ID is mandatory when selected.");
      return;
    }

    try {
      setLoading(true);
      const { selectedField, ...formDataWithoutSelectedField } = formData;
      const endpoint = "/transactions/admin-refund";
      const response = await callRestApi(endpoint, "POST", {
        userId: userId,
        ...formDataWithoutSelectedField,
      });

      if (response?.status === 201) {
        toast.success(response?.data.message || "Wallet Updated. Please check");
        setLoading(false);
        close();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <DialogTitle
        sx={{
          backgroundColor: "#00408B",
          color: "#FFFFFF",
          textAlign: "center",
          padding: "12px 24px",
          fontSize: "1.1rem",
          fontWeight: 500,
        }}
      >
        Add Money to Wallet
      </DialogTitle>
      <DialogContent>
        <Box>
          {!message ? null : (
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          )}
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <FormControl fullWidth>
            <TextField
              id="amount"
              type="text"
              label="Amount"
              variant="outlined"
              fullWidth
              value={formData.amount}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  amount: event.target.value,
                }));
              }}
            />
            <RadioGroup
              row
              aria-label="Field"
              name="selectedField"
              value={formData.selectedField}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  selectedField: event.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="orderId"
                control={<Radio />}
                label="Order ID"
              />
              <FormControlLabel
                value="tableId"
                control={<Radio />}
                label="Table ID"
              />
            </RadioGroup>
            {formData.selectedField === "orderId" ? (
              <TextField
                id="orderId"
                label="Order ID"
                fullWidth
                variant="outlined"
                required
                value={formData.orderId}
                onChange={(event) => {
                  setFormData((prev) => ({
                    ...prev,
                    orderId: event.target.value,
                  }));
                }}
              />
            ) : (
              <TextField
                id="tableId"
                label="Table ID"
                fullWidth
                variant="outlined"
                required
                value={formData.tableId}
                onChange={(event) => {
                  setFormData((prev) => ({
                    ...prev,
                    tableId: event.target.value,
                  }));
                }}
              />
            )}
          </FormControl>
          <FormControl>
            <FormLabel
              id="walletLabel"
              required
              sx={{
                backgroundColor: "#00408B",
                color: "#FFFFFF",
                padding: "4px 12px",
                borderRadius: "4px",
                fontSize: "1rem",
                display: "inline-block",
                marginBottom: "10px",
              }}
            >
              Select Game
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="gameLabel"
              name="game"
              value={formData.game}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  game: event.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="ludo"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/ludo_logo_1.png"
                      alt="Ludo"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Ludo
                  </Box>
                }
              />

              <FormControlLabel
                value="skillpatti"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/skillpatti_logo_1.png"
                      alt="Skillpatti"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Skillpatti
                  </Box>
                }
              />

              <FormControlLabel
                value="callbreak"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/callbreak_logo_1.png"
                      alt="Callbreak"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Callbreak
                  </Box>
                }
              />

              <FormControlLabel
                value="snakesandladders"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/snakesandladders_logo_1.png"
                      alt="Snakeandladder"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Snakeandladder
                  </Box>
                }
              />

              <FormControlLabel
                value="rummy"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/rummy_logo_1.png"
                      alt="Rummy"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Rummy
                  </Box>
                }
              />

              <FormControlLabel
                value="handcricket"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/handcricket_logo_1.png"
                      alt="Handcricket"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Handcricket
                  </Box>
                }
              />

              <FormControlLabel
                value="aviator"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/images/aviator_logo_1.png"
                      alt="Aviator"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    Aviator
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel
              id="walletLabel"
              required
              sx={{
                backgroundColor: "#00408B",
                color: "#FFFFFF",
                padding: "4px 12px",
                borderRadius: "4px",
                fontSize: "1rem",
                display: "inline-block",
                marginBottom: "10px",
              }}
            >
              Select Wallet
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="walletLabel"
              name="wallet"
              value={formData.wallet}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  wallet: event.target.value,
                }));
              }}
            >
              <FormControlLabel
                value="main"
                control={<Radio />}
                label={
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <img
                      src="/images/main wallter.png"
                      alt="Main Wallet"
                      style={{ width: "24px", height: "24px" }}
                    />
                    Main
                  </Box>
                }
              />
              <FormControlLabel
                value="win"
                control={<Radio />}
                label={
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <img
                      src="/images/win wallet.png"
                      alt="Win Wallet"
                      style={{ width: "24px", height: "24px" }}
                    />
                    Win
                  </Box>
                }
              />
              <FormControlLabel
                value="bonus"
                control={<Radio />}
                label={
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <img
                      src="/images/bonus wallet.png"
                      alt="Bonus Wallet"
                      style={{ width: "24px", height: "24px" }}
                    />
                    Bonus
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "16px" }}>
        <Button
          onClick={close}
          color="info"
          variant="contained"
          sx={{ minWidth: "120px", marginRight: "16px" }}
        >
          Cancel
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onSave}
          disabled={loading}
          sx={{ minWidth: "120px" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletTransactionModal;
