import React, { useState } from "react";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import { TextField, Button, Typography } from "@mui/material";
const ExportGameHistory = () => {
  const [gameType, setGameType] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const handleExport = async () => {
    if (!gameType || !username || !startDate || !endDate) {
      setStatus("Please fill in all fields");
      return;
    }

    const requestBody = {
      title: "CS_File",
      username: username,
      gameType: gameType,
      startedAt: startDate,
      endedAt: endDate,
    };

    setStatus("Exporting...");

    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(config.REST_ENDPOINT + "/api/tasks/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.fileUrl) {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.fileUrl;
          a.download = `gameHistory_${gameType}.csv`;

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);

          setStatus("Export successful, CSV file downloaded.");
        } else {
          setStatus("Export successful, but no file URL provided.");
        }
      } else if (response.status === 401) {
        setStatus("Authentication failed. Please check your credentials.");
      } else {
        const errorData = await response.json().catch(() => ({}));
        setStatus(
          `Export failed: ${response.status} ${response.statusText}. ${
            errorData.message || ""
          }`
        );
      }
    } catch (error) {
      console.error("Error: ", error);
      setStatus(
        `Error during export: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
    }
  };
  return (
    <div
      style={{
        width: "600px",
        border: "1px solid #ccc",
        padding: "30px",
        borderRadius: "8px",
        backgroundColor: "white",
        margin: "auto",
        marginTop: "20px",
      }}
    >
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          color: "#00408B",
          marginBottom: "30px",
          fontWeight: 700, // Makes text bold
        }}
      >
        EXPORT GAME HISTORY
      </Typography>

      {/* Username Search Box */}
      <Typography
        variant="h6"
        style={{ color: "#00408B", marginBottom: "10px" }}
      >
        Username
      </Typography>
      <TextField
        fullWidth
        placeholder="Search Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{
          marginBottom: "30px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#00408B",
            },
            "&:hover fieldset": {
              borderColor: "#00408B",
            },
          },
        }}
      />

      {/* Game Selection with Images */}
      <Typography
        variant="h6"
        style={{ color: "#00408B", marginBottom: "15px" }}
      >
        Select Game
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // 3 images per row
          gap: "10px", // Small uniform gap
          marginBottom: "30px",
        }}
      >
        {[
          { value: "ludo", image: "/images/ludo_logo_1.png" },
          { value: "skillpatti", image: "/images/skillpatti_logo_1.png" },
          { value: "rummy", image: "/images/rummy_logo_1.png" },
          {
            value: "snakesandladders",
            image: "/images/snakesandladders_logo_1.png",
          },
          { value: "aviator", image: "/images/aviator_logo_1.png" },
          { value: "callbreak", image: "/images/callbreak_logo_1.png" },
          { value: "handcricket", image: "/images/handcricket_logo_1.png" },
        ].map((game) => (
          <div
            key={game.value}
            onClick={() => setGameType(game.value)}
            style={{
              cursor: "pointer",
              border:
                gameType === game.value
                  ? "2px solid #00408B"
                  : "1px solid #ccc",
              borderRadius: "8px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              aspectRatio: "1", // Makes containers square
            }}
          >
            <img
              src={game.image}
              alt={game.value}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ))}
      </div>
      {/* Date Range Selection */}
      <Typography
        variant="h6"
        style={{ color: "#00408B", marginBottom: "15px" }}
      >
        Select Date Range
      </Typography>
      <div style={{ display: "flex", gap: "20px", marginBottom: "30px" }}>
        <TextField
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00408B",
              },
            },
          }}
        />
        <TextField
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00408B",
              },
            },
          }}
        />
      </div>

      {/* Export Button */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <img
          src="/images/exportbutton.png"
          alt="Export"
          style={{
            width: "60px",
            height: "60px",
            objectFit: "contain",
            cursor: "pointer",
          }}
          onClick={handleExport}
        />
      </div>

      {status && (
        <Typography
          style={{
            marginTop: "15px",
            color: status.includes("successful") ? "green" : "red",
            textAlign: "center",
          }}
        >
          {status}
        </Typography>
      )}
    </div>
  );
};

export default ExportGameHistory;
