import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
} from "react-admin";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { useState } from "react";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import KycZoopImageModal from "../components/KycZoopImageModal";

const KycZoop = (props: object) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState({});
  const { permissions } = usePermissions();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by CardType"
      source="kyc.data.cardType"
      choices={[
        { id: "aadhaar", name: "Adhaar" },
        { id: "pan", name: "PAN" },
      ]}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>KYC Records</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="username" to="_id" />
          <TextField source="kyc.data.cardType" label="Card Type" />
          <FunctionField
            source="kyc.status"
            label="Kyc Status"
            render={(record: any) => (record.kyc.status ? "Success" : "Failed")}
          />
          <FunctionField
            label="Cards"
            render={(record: any) => {
              if (record.kyc.data?.cardType === "aadhaar") {
                return (
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="small outlined button group"
                  >
                    <Button
                      color="success"
                      onClick={() => {
                        setImageModalOpen(true);
                        setImageModalData(() => {
                          return { ...record };
                        });
                      }}
                    >
                      Card Number
                    </Button>
                  </ButtonGroup>
                );
              } else {
                return (
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="small outlined button group"
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        setImageModalOpen(true);
                        setImageModalData(() => {
                          return {
                            ...record,
                            imageToDisplay: record.kyc.frontImageUrl,
                          };
                        });
                      }}
                    >
                      Pan Image
                    </Button>
                  </ButtonGroup>
                );
              }
            }}
          />
          <DateField source="updatedAt" showTime label="Created At" />
        </DatagridConfigurable>
      </List>
      {imageModalOpen && (
        <KycZoopImageModal
          open={imageModalOpen}
          close={() => setImageModalOpen(false)}
          data={imageModalData}
        />
      )}
    </>
  );
};

export default KycZoop;
