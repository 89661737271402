import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  DeleteForever,
  ModeEdit,
  RemoveRedEye,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Confirm,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateTimeInput,
  ExportButton,
  Filter,
  FilterButton,
  FilterForm,
  FilterProps,
  FunctionField,
  List,
  SearchInput,
  SelectArrayInput,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  usePermissions,
  useRefresh,
} from "react-admin";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Box, Stack } from "@mui/material";

import WinningsPrizeModal from "./WinningsPrizeModal";
import { callRestApi } from "../../utils/callRestApi";

const Tournaments = (props: any) => {
  const [winningPrizeModal, setWinningPrizeModal] = useState(false);
  const [winningPrize, setWinningPrize] = useState();
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");
  const [loading, setLoading] = useState(false);
  const [isActiveChangeModalOpen, setIsActiveChangeModalOpen] = useState(false);
  const [isActiveChangeRecord, setIsActiveChangeRecord] = useState();

  const refresh = useRefresh();
  const HeaderFilters = (props: any) => (
    <Box display="flex" alignItems="center">
      <Filter {...props}>
        <SearchInput
          placeholder="Search By Tournament Name"
          source="name"
          resettable
          alwaysOn
        />
      </Filter>
      <TournamentIdFilter {...props} />
    </Box>
  );
  const TournamentIdFilter = (props: FilterProps) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search By Tournament ID"
        source="_id"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const toolbarFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "live", name: "Live" },
        { id: "created", name: "Created" },
        { id: "started", name: "Started" },
        { id: "ended", name: "Ended" },
        { id: "canceled", name: "Canceled" },
      ]}
    />,
    <BooleanInput source="isActive" label="Filter By Active Status" />,
    <BooleanInput source="isRepeatable" label="Filter By Repeatable" />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={toolbarFilters} />
    </Stack>
  );

  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={toolbarFilters} />
    </TopToolbar>
  );

  const handleDeleteTournament = async (recordId: string) => {
    try {
      setLoading(true);
      const { status } = (await callRestApi(
        `/tournaments/${recordId}`,
        "DELETE"
      )) as AxiosResponse;
      if (status === 200) {
        toast("Tournament Deleted Successfully", {
          type: "success",
        });
      }
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setDeleteConfirmModalOpen(false);
    setLoading(false);
    refresh();
  };

  const handleIsActiveChange = async (record: any) => {
    try {
      setLoading(true);
      const { status } = (await callRestApi(
        `/ludo/tournament/${record.id}/cancel`,
        "POST",
        {},
        `isActive=${!record.isActive}`
      )) as AxiosResponse;
      if (status === 200) {
        toast(
          `Tournament ${
            record.isActive ? "Deactivated" : "Activated"
          } Successfully`,
          {
            type: "success",
          }
        );
      }
      setLoading(false);
      setIsActiveChangeModalOpen(false);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setDeleteConfirmModalOpen(false);
    setLoading(false);
    refresh();
  };
  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Tournaments Dashboard </h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<HeaderFilters />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <FunctionField
            label="Tournament Name"
            textAlign="center"
            render={(record: any) => {
              return (
                <Link to={`/ludo/tournament/${record?.id}/show`}>
                  {record.name}
                </Link>
              );
            }}
          />
          <FunctionField
            label="Tournament Id"
            textAlign="center"
            render={(record: any) => {
              return (
                <Link to={`/ludo/tournament/${record?.id}/show`}>
                  {record.id}
                </Link>
              );
            }}
          />
          <TextField source="alias" label="Alias" />
          <DateField source="registerTill" label="Register Till" showTime />
          <DateField source="startAt" label="Starts At" showTime />
          <DateField source="endAt" label="Ends At" showTime />
          <FunctionField
            label="Entry Fee"
            textAlign="center"
            render={(record: any) => {
              return "₹" + record.joinFee;
            }}
          />
          <FunctionField
            label="Status"
            textAlign="center"
            render={(record: any) => {
              switch (record.status) {
                case "created":
                  return <ChipField source="status" color="warning" />;
                case "started":
                  return <ChipField source="status" color="success" />;
                case "ended":
                  return <ChipField source="status" color="info" />;
                case "cancelled":
                  return <ChipField source="status" color="error" />;
                default:
                  return <ChipField source="status" color="primary" />;
              }
            }}
          />
          <FunctionField
            label="Winning Prize"
            textAlign="center"
            render={(record: any) => {
              return (
                <RemoveRedEye
                  titleAccess="View Winning Prizes"
                  color="primary"
                  onClick={() => {
                    setWinningPrizeModal(true);
                    setWinningPrize(() => {
                      return record;
                    });
                  }}
                  fontSize="large"
                  sx={{ "&:hover": { color: "green" }, cursor: "pointer" }}
                />
              );
            }}
          />
          <TextField source="currentRoundNo" label="Current Round" />
          <BooleanField source="isActive" label="Active" />
          <BooleanField source="isRepeatable" label="Repeatable" />
          <BooleanField source="isAutomatic" label="Automatic" />
          <BooleanField source="featured" />

          <FunctionField
            textAlign="center"
            label="Actions"
            render={(record: any) => {
              if (!record.isDeleted && record.status === "created") {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Link to={`/ludo/tournament/${record?.id}/edit`}>
                      <ModeEdit
                        titleAccess="Edit Tournament"
                        fontSize="large"
                        sx={{ "&:hover": { color: "green" } }}
                        color="warning"
                      />
                    </Link>

                    {record.isActive ? (
                      <ToggleOn
                        titleAccess="De-Activate Tournament"
                        fontSize="large"
                        sx={{ "&:hover": { color: "red" }, cursor: "pointer" }}
                        color="success"
                        onClick={() => {
                          setIsActiveChangeRecord(() => record);
                          setIsActiveChangeModalOpen(true);
                        }}
                      />
                    ) : (
                      <ToggleOff
                        titleAccess="Activate Tournament"
                        fontSize="large"
                        sx={{
                          "&:hover": { color: "green" },
                          cursor: "pointer",
                        }}
                        color="error"
                        onClick={() => {
                          setIsActiveChangeRecord(() => record);
                          setIsActiveChangeModalOpen(true);
                        }}
                      />
                    )}

                    {!record.isDeleted && (
                      <DeleteForever
                        onClick={() => {
                          setDeleteConfirmModalOpen(true);
                          setDeleteRecord(record?.id);
                        }}
                        color="error"
                        titleAccess="Delete Tournament"
                        fontSize="large"
                        sx={{
                          "&:hover": { color: "green" },
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                );
              }
            }}
          />
        </DatagridConfigurable>
      </List>

      {winningPrizeModal && (
        <WinningsPrizeModal
          open={winningPrizeModal}
          close={setWinningPrizeModal}
          data={winningPrize}
        />
      )}

      {deleteConfirmModalOpen && (
        <Confirm
          isOpen={deleteConfirmModalOpen}
          title={`Please Confirm the Action`}
          content="Are you sure want to delete tournament?"
          onConfirm={() => handleDeleteTournament(deleteRecord)}
          onClose={() => setDeleteConfirmModalOpen(false)}
          loading={loading}
        />
      )}

      {isActiveChangeModalOpen && (
        <Confirm
          isOpen={isActiveChangeModalOpen}
          title={`Please Confirm the Action`}
          content={`Are you sure want to ${
            get(isActiveChangeRecord, "isActive", "take action on this")
              ? "de-activate"
              : "activate"
          } tournament?`}
          onConfirm={() => handleIsActiveChange(isActiveChangeRecord)}
          onClose={() => setIsActiveChangeModalOpen(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default Tournaments;
