import {
  DataGrid,
  GridRowParams,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useParams } from "react-router";
import querystring from "query-string";
import { memo, useEffect, useState } from "react";
import { Box, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { camelCase, get, isEmpty, remove, set, size, startCase } from "lodash";

import UserInfoRow from "../../../components/UserInfoRow";
import RoundCard from "./RoundCard";
import { callRestApi } from "../../../utils/callRestApi";
import { convertToDotNotation } from "../../../utils/dotNotationConverter";
import Item from "../../../components/GridItem";
import Big from "big.js";
import { table } from "console";

const GameRoundHistory = () => {
  const { tableId, userId } = useParams();
  const [roundInfo, setRoundInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [apiData, setApiData] = useState([]);
  const [apiMeta, setApiMeta] = useState({
    totalCount: 0,
    skip: 0,
    count: 5,
  });
  const [payloadMeta, setPayloadMeta] = useState({
    count: 5,
    skip: 0,
    page: 0,
    filter: {},
    sortBy: "createdAt",
    sortDir: -1,
  });

  const gameRoundsRecordsColumns = [
    {
      field: "roundNo",
      headerName: "Round",
    },
    {
      field: "potAmount",
      headerName: "Pot Amount",
      valueFormatter: (params: any) => {
        const potAmount = new Big(params.value);
        return potAmount.toFixed(2);
      },
    },
    {
      field: "userInfo",
      headerName: "Players",
      renderCell: (params: { row: any }) => {
        return size(params.row.userInfo);
      },
    },
    {
      field: "roundStartedAt",
      headerName: "Date Time",
    },
    {
      field: "_id",
      headerName: "Action",
      disableClickEventBubbling: true,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <img
          src="/images/show.png"
          alt="Show"
          style={{
            width: "35px",
            height: "35px",
            cursor: "pointer",
            objectFit: "contain",
          }}
          onClick={(e) => {
            setRoundInfo(row);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [payloadMeta]);

  const handlePaginationChange = (event: any) => {
    const { page, pageSize } = event;
    setPayloadMeta((prev) => ({
      ...prev,
      skip: Math.abs(page * payloadMeta.count),
      count: pageSize,
      page,
    }));
  };

  const handleSortChange = (event: any) => {
    try {
      const { field, sort } = event[0];
      setPayloadMeta((prev) => ({
        ...prev,
        sortBy: field,
        sortDir: sort === "asc" ? 1 : -1,
      }));
    } catch (error) {
      setPayloadMeta((prev) => ({
        ...prev,
        sortBy: "createdAt",
        sortDir: -1,
      }));
    }
  };

  const handleFilterChange = (event: any) => {
    try {
      const { field, value } = event?.items[0];
      setPayloadMeta((prev) => {
        const filterValue: object = {};
        set(filterValue, field, value);
        return {
          ...prev,
          filter: { ...prev.filter, ...filterValue },
        };
      });
    } catch (error) {
      setPayloadMeta((prev) => ({
        ...prev,
      }));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const fetchData = async () => {
    let query = {
      filter: JSON.stringify(
        convertToDotNotation({
          ...payloadMeta.filter,
          tableId,
          "userInfo.userId": userId,
          roundNo: searchTerm,
        })
      ),
      count: payloadMeta.count,
      skip: payloadMeta.skip,
      sortBy: payloadMeta.sortBy,
      sortDir: payloadMeta.sortDir,
    };

    if (!searchTerm) {
      query = {
        filter: JSON.stringify(
          convertToDotNotation({
            ...payloadMeta.filter,
            tableId,
            userInfo: userId,
          })
        ),
        count: payloadMeta.count,
        skip: payloadMeta.skip,
        sortBy: payloadMeta.sortBy,
        sortDir: payloadMeta.sortDir,
      };
    }
    setLoading(true);
    try {
      const apiResponse = await callRestApi(
        `/api/sp-round-history`,
        "GET",
        {},
        querystring.stringify(query)
      );
      setApiData(() => apiResponse?.data?.items);
      setApiMeta(() => apiResponse?.data?.meta);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <h3 style={{ marginLeft: "24px" }}>Table Details</h3>
          <Item>
            <Box>
              <Box marginTop={2} marginBottom={2}>
                {isEmpty(roundInfo) ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    <h4>No Round Selected in Table</h4>
                  </Box>
                ) : (
                  <>
                    <UserInfoRow
                      label="Table Id"
                      value={get(roundInfo, "tableId", "")}
                    />

                    {remove(
                      Object.keys(get(roundInfo, "tableType", [])),
                      (n) => n !== "_id"
                    ).map((value, i) => {
                      return (
                        <UserInfoRow
                          label={startCase(camelCase(value))}
                          value={get(roundInfo, `tableType.${value}`, "")}
                          key={i}
                        />
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Item>
          <h3 style={{ marginLeft: "24px" }}>Game Rounds</h3>
          <Item>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Search Round"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ marginRight: "16px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchData()}
              >
                Search
              </Button>
            </Box>
            <Box sx={{ height: 420, width: "100%", overflowX: "auto" }}>
              <DataGrid
                rows={apiData}
                columns={gameRoundsRecordsColumns}
                rowCount={apiMeta.totalCount}
                paginationModel={{
                  page: payloadMeta.page,
                  pageSize: payloadMeta.count,
                }}
                loading={loading}
                pagination
                paginationMode={"server"}
                pageSizeOptions={[5, 10, 20, 30, 50, 100]}
                disableRowSelectionOnClick
                slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
                onFilterModelChange={handleFilterChange}
                onSortModelChange={handleSortChange}
                onPaginationModelChange={handlePaginationChange}
                getRowId={(row) => row._id}
              />
            </Box>
          </Item>
        </Grid>
        <Grid item xs={8}>
          {isEmpty(roundInfo) ? (
            <Item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <h4>No Round Selected in Table</h4>
              </Box>
            </Item>
          ) : (
            <>
              <h3
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                Round Details
              </h3>
              <Box sx={{ height: "25%" }}>
                <RoundCard data={roundInfo} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default memo(GameRoundHistory);
