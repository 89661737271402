import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite.css";
// import { Chart as ChartJS, CategoryScale, LinearScale,Tooltip,  } from 'chart.js';
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

// ChartJS.register(CategoryScale,  BarElement, Title, Tooltip, Legend);

const getCountryCode = (countryName: string) => {
  const normalizedName = countryName.toLowerCase();
  const specialCases: { [key: string]: string } = {
    "bolivia, plurinational state of": "bo",
    "tanzania, united republic of": "tz",
    "united states of america": "us",
    india: "in",
    bangladesh: "bd",
    pakistan: "pk",
    nepal: "np",
    myanmar: "mm",
  };
  return specialCases[normalizedName] || normalizedName.slice(0, 2);
};

const flagsPlugin = {
  id: "flagsPlugin",
  afterDraw: (chart: any) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    chart.data.labels.forEach((label: string, index: number) => {
      const flagImg = new Image();
      const countryCode = getCountryCode(label);
      flagImg.src = `https://flagcdn.com/w20/${countryCode}.png`;
      const x = xAxis.getPixelForValue(index);
      const y = yAxis.getPixelForValue(0) + 20;

      flagImg.onload = () => {
        ctx.drawImage(flagImg, x - 10, y, 20, 12);
      };
    });
  },
};
const CountryListDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [countryCounts, setCountryCounts] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const today = new Date();
  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfDay,
    endOfDay,
  ]);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = { startDate, endDate };
      setLoading(true);

      const response = await axios.get(
        `${config.REST_ENDPOINT}/api/users/country-list/dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const { countryCounts, totalCount } = response.data;
      setCountryCounts(countryCounts || []);
      setTotalCount(totalCount || 0);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch country data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  const chartData = {
    labels: countryCounts.map((country) => country.country),
    datasets: [
      {
        label: "Users by Country",
        data: countryCounts.map((country) => country.count),
        backgroundColor: "#00408B80",
        borderColor: "#00408B",
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `Users: ${context.raw.toLocaleString()}`,
          title: (tooltipItems: any) => tooltipItems[0].label,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Users",
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          padding: 25,
        },
      },
    },
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", margin: "auto" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2
        style={{
          fontFamily: "Playfair Display",
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        Country List Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          marginBottom: 20,
          backgroundColor: "#00408B",
          color: "white",
        }}
      >
        Total Users: {totalCount.toLocaleString()}
      </Typography>

      <Box sx={{ height: "600px", padding: 2 }}>
        <Bar data={chartData} options={chartOptions} plugins={[flagsPlugin]} />
      </Box>
    </>
  );
};

export default CountryListDashboard;
