import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, usePermissions } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import {
  Badge,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Box,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getItemWithExpiry } from "../utils";
import config from "../config";

interface RowData {
  userId: string;
  username: string;
  roles: string[];
  mobileNumber: {
    countryCode: string;
    number: string;
  };
}

interface UserProfile {
  "User Id": string;
  Username: string;
  "Mobile Number": string;
  "My current Role": string;
}

const RoleStatisticsGraph: React.FC<{
  adminCount: number;
  financeCount: number;
  supportCount: number;
  developerCount: number;
}> = ({ adminCount, financeCount, supportCount, developerCount }) => {
  const data = [
    { name: "Admin Users", count: adminCount },
    { name: "Finance Users", count: financeCount },
    { name: "Support Users", count: supportCount },
    { name: "Developer Users", count: developerCount },
  ];

  return (
    <Card sx={{ p: 2, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Role Distribution
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="count"
            fill="#00408B80"
            stroke="#00408B"
            strokeWidth={3}
            barSize={40}
            name="Total Users"
          />{" "}
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState<RowData[]>([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [totalFinance, setTotalFinance] = useState(0);
  const [totalSupports, setTotalSupports] = useState(0);
  const [totalDevelopers, setTotalDevelopers] = useState(0);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const { permissions } = usePermissions();

  const fetchUserProfile = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        throw new Error("You have no access");
      }
      const response = await axios.get(`${config.REST_ENDPOINT}/api/profile`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUserProfile(response.data.roleObject);
    } catch (error: any) {
      console.error("Error fetching user profile:", error);
      toast.error(error.message || "Error fetching user profile");
    }
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const response = await axios.get(
        `${config.REST_ENDPOINT}/api/profile?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGameData(response.data.users);
      setTotalAdmins(response.data.roleCounts.admin);
      setTotalFinance(response.data.roleCounts.finance);
      setTotalSupports(response.data.roleCounts.support);
      setTotalDevelopers(response.data.roleCounts.developer);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
    fetchUserProfile();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      renderCell: (params: any) => {
        if (permissions.includes("admin")) {
          return (
            <Link to={`/api/users/${params.row.userId}/show`}>
              {params.row.username}
            </Link>
          );
        }
        return params.row.username;
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      valueGetter: (params: any) =>
        `${params.row.mobileNumber.countryCode} ${params.row.mobileNumber.number}`,
    },
    { field: "roles", headerName: "Roles", flex: 1 },
  ];

  const filteredData = useMemo(() => {
    return gameData.filter((user) => {
      const matchesUsername = usernameFilter
        ? user.username.toLowerCase().includes(usernameFilter.toLowerCase())
        : true;
      const matchesRole = selectedRole
        ? user.roles.includes(selectedRole)
        : true;
      return matchesUsername && matchesRole;
    });
  }, [gameData, usernameFilter, selectedRole]);

  const RoleFilterButtons = () => (
    <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
      {["admin", "finance", "support", "developer"].map((role) => (
        <Button
          key={role}
          variant={selectedRole === role ? "contained" : "outlined"}
          onClick={() => setSelectedRole(role === selectedRole ? "" : role)}
          sx={{
            color: selectedRole === role ? "#fff" : "#00408B",
            backgroundColor: selectedRole === role ? "#00408B" : "transparent",
            borderColor: "#00408B",
            "&:hover": {
              backgroundColor:
                selectedRole === role ? "#00408B" : "rgba(0, 64, 139, 0.1)",
              borderColor: "#00408B",
            },
          }}
        >
          {role.charAt(0).toUpperCase() + role.slice(1)}
        </Button>
      ))}
    </Box>
  );
  return (
    <div style={{ padding: "20px" }}>
      <h2 style={{ fontFamily: "Playfair Display" }}>Admin Profile</h2>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {userProfile && (
            <Card
              elevation={3}
              style={{
                textAlign: "left",
                height: "250px",
                maxWidth: "280px",
                width: "100%",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Playfair Display",
                    marginBottom: "20px",
                    color: "#00408B",
                  }}
                >
                  Profile Information
                </Typography>
                <Box
                  style={{
                    marginBottom: "17px",
                  }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    style={{ marginLeft: "85px" }}
                  >
                    <Avatar alt="Profile" src="/images/profile.png" />
                  </Badge>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ marginBottom: "8px", color: "#555" }}
                  >
                    <strong>Username:</strong> {userProfile["Username"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ marginBottom: "8px", color: "#555" }}
                  >
                    <strong>Mobile Number:</strong>{" "}
                    {userProfile["Mobile Number"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ marginBottom: "8px", color: "#555" }}
                  >
                    <strong>My Current Role:</strong>{" "}
                    {userProfile["My current Role"]}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item xs={12} md={8}>
          <RoleStatisticsGraph
            adminCount={totalAdmins}
            financeCount={totalFinance}
            supportCount={totalSupports}
            developerCount={totalDevelopers}
          />
        </Grid>
      </Grid>

      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search Username"
          variant="outlined"
          value={usernameFilter}
          onChange={(e) => setUsernameFilter(e.target.value)}
          fullWidth
        />
      </Box>

      <RoleFilterButtons />

      <Paper elevation={3} sx={{ p: 2 }}>
        <DataGrid
          columns={columns}
          rows={filteredData}
          loading={loading}
          getRowId={(row) => row.userId}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50]}
          autoHeight
          rowCount={filteredData.length}
        />
      </Paper>
    </div>
  );
};

export default AdminPage;
