import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../../../config";
import { getItemWithExpiry } from "../../../utils";
import { toast } from "react-toastify";
import StatsCard from "../../../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "rsuite/dist/rsuite.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RummyDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [gameDashboardStats, setGameDashboardStats] = useState<any>({});

  const date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#009933",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user?.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
      };

      setLoading(true);

      const gameDashboardResponse = await axios.get(
        `${config.REST_ENDPOINT}/api/rummy/dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGameDashboardStats(gameDashboardResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange]);

  const gameStatsData = [
    {
      name: "Total Games",
      value: gameDashboardStats.totalGamesCount ?? 0,
      color: "#6699CC",
    },
    {
      name: "Join Fee Amount",
      value: gameDashboardStats.totalJoinFeesAmount ?? 0,
      color: "#9B7EBD",
    },
    {
      name: "Commission Amount",
      value: gameDashboardStats.totalCommissionAmount ?? 0,
      color: "#629584",
    },
    {
      name: "Total Rounds",
      value: gameDashboardStats.totalRoundsCount ?? 0,
      color: "#6A1E55",
    },
    
    
    {
      name: "Games Won",
      value: gameDashboardStats.totalWonCount ?? 0,
      color: "#8EACCD",
    },
    {
      name: "Games Lost",
      value: gameDashboardStats.totalLostCount ?? 0,
      color: "#987070",
    },
  ];

  const joinAmountData = Object.entries(
    gameDashboardStats.joinFeeDistribution || {}
  ).map(([key, value]) => ({
    joinAmount: `₹${key}`,
    tables: value,
  }));

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
    <h2
      style={{
        fontFamily: "Playfair Display",
        textAlign: "center",
        margin: "20px 0",
      }}
    >
      Rummy Game Dashboard
    </h2>

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
        marginTop: "10px",
      }}
    >
      <p style={{ marginRight: "10px" }}>
        <strong>Select Date Range:</strong>
      </p>
      <DateRangePicker value={dateRange} onChange={handleDateSelect} />
    </div>

      <Box
        sx={{
          mt: 4,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          height: "500px",
          width: "80%",
          margin: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mb: 2,
            bgcolor: "#00408B",
            color: "white",
            p: 1,
          }}
        >
          Game Statistics
        </Typography>

        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={gameStatsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} textAnchor="end" height={60} />
            <YAxis
              tickFormatter={(value) =>
                value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value
              }
            />
            <Tooltip formatter={(value) => value.toLocaleString("en-IN")} />
            <Legend />
            <Bar
              dataKey="value"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Box
        sx={{
          mt: 4,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          marginBottom: "40px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mb: 2,
            bgcolor: "#00408B",
            color: "white",
            p: 1,
          }}
        >
          Join Fee Distribution
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={joinAmountData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="joinAmount" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="tables"
              fill="#00408B80"
              stroke="#00408B"
              strokeWidth={3}
              barSize={40}
              name="Number of Tables"
            />          </BarChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default RummyDashboard;
