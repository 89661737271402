import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { callRestApi } from "../utils/callRestApi";

interface ConversionRate {
  country: string;
  conversionRate: number;
  currencyCode: string;
  currencySymbol: string;
}

interface CountryData {
  total: number;
  countries: ConversionRate[];
}

const initialCountryData: CountryData = {
  total: 31,
  countries: [
    {
      country: "India",
      conversionRate: 1,
      currencyCode: "INR",
      currencySymbol: "₹",
    },
    {
      country: "Bangladesh",
      conversionRate: 1.42,
      currencyCode: "BDT",
      currencySymbol: "৳",
    },
    {
      country: "Pakistan",
      conversionRate: 3.3,
      currencyCode: "PKR",
      currencySymbol: "Rs",
    },
    {
      country: "Kenya",
      conversionRate: 1.53,
      currencyCode: "KES",
      currencySymbol: "KES",
    },
    {
      country: "Uzbekistan",
      conversionRate: 151.92,
      currencyCode: "UZS",
      currencySymbol: "лв",
    },
    {
      country: "Myanmar",
      conversionRate: 25,
      currencyCode: "MMK",
      currencySymbol: "K",
    },
    {
      country: "Tunisia",
      conversionRate: 0.037,
      currencyCode: "TND",
      currencySymbol: "د.ت",
    },
    {
      country: "Brazil",
      conversionRate: 0.065,
      currencyCode: "BRL",
      currencySymbol: "R$",
    },
    {
      country: "Mexico",
      conversionRate: 0.23,
      currencyCode: "MXN",
      currencySymbol: "$",
    },
    {
      country: "Cambodia",
      conversionRate: 48.38,
      currencyCode: "KHR",
      currencySymbol: "៛",
    },
    {
      country: "Nepal",
      conversionRate: 1.6,
      currencyCode: "NPR",
      currencySymbol: "₨",
    },
    {
      country: "Argentina",
      conversionRate: 11.61,
      currencyCode: "ARS",
      currencySymbol: "$",
    },
    {
      country: "Bolivia",
      conversionRate: 0.082,
      currencyCode: "BOB",
      currencySymbol: "$b",
    },
    {
      country: "Chile",
      conversionRate: 11.07,
      currencyCode: "CLP",
      currencySymbol: "$",
    },
    {
      country: "Colombia",
      conversionRate: 50.19,
      currencyCode: "COP",
      currencySymbol: "$",
    },
    {
      country: "Costa Rica",
      conversionRate: 6.18,
      currencyCode: "CRC",
      currencySymbol: "₡",
    },
    {
      country: "Dominican Republic",
      conversionRate: 0.72,
      currencyCode: "DOP",
      currencySymbol: "RD$",
    },
    {
      country: "Ecuador",
      conversionRate: 0.012,
      currencyCode: "USD",
      currencySymbol: "$",
    },
    {
      country: "Egypt",
      conversionRate: 0.58,
      currencyCode: "EGP",
      currencySymbol: "£",
    },
    {
      country: "El Salvador",
      conversionRate: 0.012,
      currencyCode: "USD",
      currencySymbol: "$",
    },
    {
      country: "Ghana",
      conversionRate: 0.19,
      currencyCode: "GHS",
      currencySymbol: "¢",
    },
    {
      country: "Guatemala",
      conversionRate: 0.092,
      currencyCode: "GTQ",
      currencySymbol: "Q",
    },
    {
      country: "Nigeria",
      conversionRate: 19.28,
      currencyCode: "NGN",
      currencySymbol: "₦",
    },
    {
      country: "Panama",
      conversionRate: 0.012,
      currencyCode: "USD",
      currencySymbol: "B/.",
    },
    {
      country: "Paraguay",
      conversionRate: 92.71,
      currencyCode: "PYG",
      currencySymbol: "Gs",
    },
    {
      country: "Peru",
      conversionRate: 0.044,
      currencyCode: "PEN",
      currencySymbol: "S/.",
    },
    {
      country: "South Africa",
      conversionRate: 0.21,
      currencyCode: "ZAR",
      currencySymbol: "R",
    },
    {
      country: "Tanzania",
      conversionRate: 32.45,
      currencyCode: "TZS",
      currencySymbol: "TZS",
    },
    {
      country: "Uganda",
      conversionRate: 43.71,
      currencyCode: "UGX",
      currencySymbol: "USh",
    },
    {
      country: "Uruguay",
      conversionRate: 0.5,
      currencyCode: "UYU",
      currencySymbol: "$U",
    },
    {
      country: "Zambia",
      conversionRate: 0.32,
      currencyCode: "ZMW",
      currencySymbol: "ZK",
    },
  ],
};

const ConversionRateManager: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [conversionRates, setConversionRates] = useState<ConversionRate[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddConversionRate = async () => {
    if (!selectedCountry) {
      setError("Please select a country");
      return;
    }

    const countryData = initialCountryData.countries.find(
      (c) => c.country === selectedCountry
    );
    if (!countryData) {
      setError("Country Already Exists");
      return;
    }

    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        toast.error("Authentication required");
        return;
      }

      const response = await fetch(
        `${config.AUTH_ENDPOINT}/payment/conversion-rate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(countryData),
        }
      );

      if (!response.ok) {
        throw new Error("Country Already Exists");
      }

      setConversionRates((prev) => [...prev, countryData]);
      setSelectedCountry("");
      setError("");
      toast.success("Conversion rate added successfully");
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "An error occurred";
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleRemoveConversionRate = async (country: string) => {
    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        toast.error("Authentication required");
        return;
      }

      const response = await fetch(
        `${config.AUTH_ENDPOINT}/payment/conversion-rate`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ country }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete conversion rate");
      }

      setConversionRates((rates) =>
        rates.filter((rate) => rate.country !== country)
      );
      toast.success("Conversion rate removed successfully");
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "An error occurred";
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  const getAvailableCountries = () => {
    const existingCountries = new Set(
      conversionRates.map((rate) => rate.country)
    );
    return initialCountryData.countries.filter(
      (country) => !existingCountries.has(country.country)
    );
  };

  const fetchConversionRates = async () => {
    setLoading(true);
    try {
      const apiResponse = await callRestApi(`/api/conversion-rate`, "GET", {});
      if (apiResponse?.status === 200) {
        setConversionRates(apiResponse.data.items);
      } else {
        console.error("Unexpected response:", apiResponse);
      }
    } catch (error) {
      console.error("API call failed:", error);
    }
    setLoading(false);
  };
  return (
    <div
      style={{
        padding: "24px",
        maxWidth: "1200px",
        margin: "0 auto",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "1.875rem",
          fontWeight: "bold",
          marginBottom: "32px",
        }}
      >
        Conversion Rate Manager
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          marginBottom: "32px",
        }}
      >
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          style={{
            flexGrow: 1,
            maxWidth: "300px",
            padding: "10px",
            border: "1px solid #E5E7EB",
            borderRadius: "0.375rem",
          }}
        >
          <option value="">Select Country</option>
          {getAvailableCountries().map((country) => (
            <option key={country.country} value={country.country}>
              {country.country} ({country.currencyCode})
            </option>
          ))}
        </select>
        <button
          onClick={handleAddConversionRate}
          style={{
            padding: "10px 24px",
            backgroundColor: "#22C55E",
            color: "white",
            border: "none",
            borderRadius: "0.375rem",
            minWidth: "100px",
            cursor: selectedCountry ? "pointer" : "not-allowed",
            opacity: selectedCountry ? 1 : 0.5,
          }}
          disabled={!selectedCountry}
        >
          Add
        </button>
        <button
          onClick={fetchConversionRates}
          style={{
            padding: "10px 24px",
            backgroundColor: "#00408B",
            color: "white",
            border: "none",
            borderRadius: "0.375rem",
            minWidth: "100px",
            cursor: "pointer",
          }}
        >
          Show
        </button>
      </div>
      {error && (
        <div
          style={{
            padding: "16px",
            marginBottom: "24px",
            color: "#B91C1C",
            backgroundColor: "#FEE2E2",
            borderLeft: "4px solid #EF4444",
            borderRadius: "0.375rem",
          }}
        >
          {error}
        </div>
      )}
      {loading ? (
        <div
          style={{
            padding: "16px",
            marginBottom: "24px",
            color: "#111827",
            backgroundColor: "#F3F4F6",
            borderLeft: "4px solid #22C55E",
            borderRadius: "0.375rem",
          }}
        >
          Loading...
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
            overflow: "hidden",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#F9FAFB" }}>
                <th
                  style={{
                    padding: "16px 24px",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "#111827",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  Country
                </th>
                <th
                  style={{
                    padding: "16px 24px",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "#111827",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  Conversion Rate
                </th>
                <th
                  style={{
                    padding: "16px 24px",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "#111827",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  Currency Code
                </th>
                <th
                  style={{
                    padding: "16px 24px",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "#111827",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  Currency Symbol
                </th>
                <th
                  style={{
                    padding: "16px 24px",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "#111827",
                    borderBottom: "1px solid #E5E7EB",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {conversionRates.map((rate) => (
                <tr key={rate.country}>
                  <td
                    style={{
                      padding: "16px 24px",
                      fontSize: "0.875rem",
                      color: "#111827",
                      borderBottom: "1px solid #E5E7EB",
                    }}
                  >
                    {rate.country}
                  </td>
                  <td
                    style={{
                      padding: "16px 24px",
                      fontSize: "0.875rem",
                      color: "#111827",
                      borderBottom: "1px solid #E5E7EB",
                    }}
                  >
                    {" "}
                    {rate.conversionRate}{" "}
                  </td>
                  <td
                    style={{
                      padding: "16px 24px",
                      fontSize: "0.875rem",
                      color: "#111827",
                      borderBottom: "1px solid #E5E7EB",
                    }}
                  >
                    {rate.currencyCode}
                  </td>
                  <td
                    style={{
                      padding: "16px 24px",
                      fontSize: "0.875rem",
                      color: "#111827",
                      borderBottom: "1px solid #E5E7EB",
                    }}
                  >
                    {rate.currencySymbol}
                  </td>
                  <td
                    style={{
                      padding: "16px 24px",
                      fontSize: "0.875rem",
                      color: "#111827",
                      borderBottom: "1px solid #E5E7EB",
                    }}
                  >
                    <button
                      onClick={() => handleRemoveConversionRate(rate.country)}
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#EF4444",
                        color: "white",
                        border: "none",
                        borderRadius: "0.375rem",
                        cursor: "pointer",
                      }}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
              {conversionRates.length === 0 && (
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      textAlign: "center",
                      color: "#6B7280",
                      fontSize: "0.875rem",
                      padding: "32px 0",
                    }}
                  >
                    No conversion rates added yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ConversionRateManager;
