import React, { useEffect, useMemo, useState } from "react";
import { useLogin, useNotify } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios, { AxiosError } from "axios";
import { Slide, MenuItem, Select } from "@mui/material";
import config from "../config";

interface ErrorResponseData {
  message: string;
}
const countryCodes = [
  { value: "+91", label: "🇮🇳 IN (+91)" },
  { value: "+92", label: "🇵🇰 PK (+92)" },
  { value: "+880", label: "🇧🇩 BD (+880)" },
  { value: "+254", label: "🇰🇪 KE (+254)" },
  { value: "+998", label: "🇺🇿 UZ (+998)" },
  { value: "+95", label: "🇲🇲 MM (+95)" },
  { value: "+216", label: "🇹🇳 TN (+216)" },
  { value: "+55", label: "🇧🇷 BR (+55)" },
  { value: "+52", label: "🇲🇽 MX (+52)" },
  { value: "+855", label: "🇰🇭 KH (+855)" },
  { value: "+977", label: "🇳🇵 NP (+977)" },
  { value: "+54", label: "🇦🇷 AR (+54)" },
  { value: "+591", label: "🇧🇴 BO (+591)" },
  { value: "+56", label: "🇨🇱 CL (+56)" },
  { value: "+57", label: "🇨🇴 CO (+57)" },
  { value: "+506", label: "🇨🇷 CR (+506)" },
  { value: "+1-809", label: "🇩🇴 DO (+1-809)" },
  { value: "+593", label: "🇪🇨 EC (+593)" },
  { value: "+20", label: "🇪🇬 EG (+20)" },
  { value: "+503", label: "🇸🇻 SV (+503)" },
  { value: "+233", label: "🇬🇭 GH (+233)" },
  { value: "+502", label: "🇬🇹 GT (+502)" },
  { value: "+234", label: "🇳🇬 NG (+234)" },
  { value: "+507", label: "🇵🇦 PA (+507)" },
  { value: "+595", label: "🇵🇾 PY (+595)" },
  { value: "+51", label: "🇵🇪 PE (+51)" },
  { value: "+27", label: "🇿🇦 ZA (+27)" },
  { value: "+255", label: "🇹🇿 TZ (+255)" },
  { value: "+256", label: "🇺🇬 UG (+256)" },
  { value: "+598", label: "🇺🇾 UY (+598)" },
  { value: "+260", label: "🇿🇲 ZM (+260)" },
];
export default function LoginPage() {
  const [mobileNo, setMobileNo] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [inputOtp, setInputOtp] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [mobileNoIsValid, setMobileNoIsValid] = useState(false);

  const validateMobileNumber = (number: any) => {
    const isValid = /^\d{10}$/.test(number);
    setMobileNoIsValid(isValid);
  };

  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    let intervalId: any;

    if (inputOtp) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [inputOtp, countdown]);

  const handleSendOTP = async () => {
    try {
      const fullPhoneNumber = `${countryCode}${mobileNo}`;
      const result = await axios.post(
        `/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          countryCode: countryCode.slice(1),
          mobileNo,
          build: {
            appVersion: "0.0.1",
            appCode: "Editor",
            isPlayStoreBuild: false,
            isGlobalBuild: false,
          },
        }
      );

      if (result.status === 201) {
        if (new Date(result.data.expiresAt) < new Date()) {
          notify("OTP is expired");
        } else {
          notify(`OTP is sent to ${fullPhoneNumber}`);
          setInputOtp(true);
          setCountdown(60);
        }
      }
    } catch (error) {
      notify("Error Sending OTP! Please try again later.");
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!inputOtp) {
      axios
        .post(`/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`, {
          mobileNo,
          countryCode,
          build: {
            appVersion: "0.0.1",
            appCode: "Editor",
            isPlayStoreBuild: false,
            isGlobalBuild: false,
          },
        })
        .then((result) => {
          if (result.status === 201) {
            if (new Date(result.data.expiresAt) < new Date()) {
              notify("OTP is expired");
              validateMobileNumber(mobileNo);
            } else {
              notify(`OTP is sent to (${mobileNo})`);
              setInputOtp(true);
              setCountdown(60);
            }
          }
        })
        .catch(() =>
          notify("Error Sending OTP! Please try again after some time.")
        );
      handleSendOTP();
    } else {
      login({ mobileNo, otp, countryCode: countryCode.slice(1) }).catch(
        (err) => {
          if (err.response?.status === 401) notify("OTP is invalid");
          else notify(err.response?.data?.message || err.message);
        }
      );
    }
  };

  const mobileNumComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            width: "100vw",
            margin: 0,
            padding: 0,
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#020635",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              background:
                "radial-gradient(circle, #015489 0%, transparent 70%)",
              opacity: 0.8,
              zIndex: 0,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "70%",
              height: "95%",
              backgroundSize: "contain",
              backgroundPosition: "left center",
              backgroundRepeat: "no-repeat",
              zIndex: 1,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "25%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              perspective: "20000px",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "400px",
                height: "250px",
                transformStyle: "preserve-3d",
                animation: "rotate 20s linear infinite",
                "@keyframes rotate": {
                  "0%": { transform: "rotateY(0deg)" },
                  "100%": { transform: "rotateY(360deg)" },
                },
              }}
            >
              {[
                "/images/aviator_banner_1.png",
                "/images/rummy_banner_1.png",
                "/images/handcricket_banner_1.png",
                "/images/snakesandladders_banner_1.png",
                "/images/skillpatti_banner_1.png",
                "/images/ludo_banner_2.png",
                "/images/callbreak_banner_1.png",
                "/images/megaludo_banner_1.png",
              ].map((img, index) => (
                <Box
                  key={index}
                  component="img"
                  src={img}
                  sx={{
                    position: "absolute",
                    width: "250px",
                    height: "250px",
                    objectFit: "cover",
                    transform: `rotateY(${index * 60}deg) translateZ(220px)`,
                    backfaceVisibility: "visible",
                    borderRadius: "12px",
                    boxShadow: "0 6px 12px rgba(0,0,0,0.2)",
                    opacity: 1,
                    transition: "opacity 0.3s",
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: "400px",
              padding: 4,
              backgroundColor: "#fff",
              borderRadius: "15px",
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
              textAlign: "center",
              position: "relative",
              marginLeft: "auto",
              marginRight: "15%",
              height: "450px",
              zIndex: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginTop: 1,
                marginBottom: 2,
                fontWeight: 800,
                fontSize: "100",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#00408B" }}>
                <img
                  src="/images/FZ1.png"
                  alt="FZ1 Logo"
                  width="40"
                  height="40"
                />
              </Avatar>
              Welcome to Fabzen Technologies
            </Typography>

            <Typography
              variant="body2"
              sx={{
                marginBottom: 1,
                textAlign: "left",
                fontWeight: 600,
                color: "#00408B",
              }}
            >
              Select Country Code
            </Typography>

            <Select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "auto",
                    minWidth: "20%",
                  },
                },
              }}
              sx={{
                marginBottom: 2,
                height: "45px",
                borderRadius: "10px",
                backgroundColor: "#fff",
                "& .MuiSelect-select": {
                  padding: "10px",
                  color: "#00408B",
                },
              }}
            >
              {countryCodes.map((country) => (
                <MenuItem
                  key={country.value}
                  value={country.value}
                  sx={{
                    fontSize: "14px",
                    padding: "8px 10px",
                  }}
                >
                  {country.label}
                </MenuItem>
              ))}
            </Select>

            <Typography
              variant="body2"
              sx={{
                marginBottom: 0,
                textAlign: "left",
                fontWeight: 600,
                color: "#00408B",
              }}
            >
              Enter Registered Mobile Number
            </Typography>

            <TextField
              fullWidth
              value={mobileNo}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                setMobileNo(numericValue);
                validateMobileNumber(numericValue);
              }}
              placeholder="1234567890"
              inputProps={{
                maxLength: 10,
                pattern: "[0-9]*",
                inputMode: "numeric",
                style: {
                  width: "100%",
                  cursor: "text",
                  caretColor: "#00408B",
                  padding: "10px",
                },
              }}
              sx={{
                marginBottom: 3,
                backgroundColor: "#fff",
                borderRadius: "10px",
                width: "100%",
                "& .MuiInputBase-input": {
                  cursor: "text",
                  width: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  width: "100%",
                  "& fieldset": {
                    borderColor: "#00408B",
                  },
                  "&:hover fieldset": {
                    borderColor: "#00408B",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00408B",
                  },
                },
              }}
            />

            <Button
              fullWidth
              variant="contained"
              onClick={handleSendOTP}
              sx={{
                backgroundColor: "#00408B",
                color: "#fff",
                height: "50px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#00408B",
                  opacity: 0.9,
                },
                "&:disabled": {
                  backgroundColor: "#00408B",
                  color: "#fff",
                  opacity: 0.6,
                },
              }}
              disabled={!mobileNoIsValid}
            >
              Send OTP
            </Button>
          </Box>
        </Box>
        :
      </Container>
    </Slide>
  );

  const otpComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            width: "100vw",
            margin: 0,
            padding: 0,
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#020635",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              background:
                "radial-gradient(circle, #015489 0%, transparent 70%)",
              opacity: 0.8,
              zIndex: 0,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "70%",
              height: "95%",
              backgroundSize: "contain",
              backgroundPosition: "left center",
              backgroundRepeat: "no-repeat",
              zIndex: 1,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "25%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              perspective: "20000px",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "400px",
                height: "250px",
                transformStyle: "preserve-3d",
                animation: "rotate 20s linear infinite",
                "@keyframes rotate": {
                  "0%": { transform: "rotateY(0deg)" },
                  "100%": { transform: "rotateY(360deg)" },
                },
              }}
            >
              {[
                "/images/aviator_banner_1.png",
                "/images/rummy_banner_1.png",
                "/images/handcricket_banner_1.png",
                "/images/snakesandladders_banner_1.png",
                "/images/skillpatti_banner_1.png",
                "/images/ludo_banner_2.png",
                "/images/callbreak_banner_1.png",
                "/images/megaludo_banner_1.png",
              ].map((img, index) => (
                <Box
                  key={index}
                  component="img"
                  src={img}
                  sx={{
                    position: "absolute",
                    width: "250px",
                    height: "250px",
                    objectFit: "cover",
                    transform: `rotateY(${index * 60}deg) translateZ(220px)`,
                    backfaceVisibility: "visible",
                    borderRadius: "12px",
                    boxShadow: "0 6px 12px rgba(0,0,0,0.2)",
                    opacity: 1,
                    transition: "opacity 0.3s",
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: "400px",
              padding: 4,
              backgroundColor: "#fff",
              borderRadius: "15px",
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
              textAlign: "center",
              position: "relative",
              marginLeft: "auto",
              marginRight: "15%",
              height: "450px",
              zIndex: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginTop: 1,
                marginBottom: 3,
                fontWeight: 800,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#00408B" }}>
                <img
                  src="/images/FZ1.png"
                  alt="FZ1 Logo"
                  width="40"
                  height="40"
                />
              </Avatar>
              Welcome to Fabzen Technologies
            </Typography>

            <Typography
              variant="body2"
              sx={{
                marginBottom: 1,
                textAlign: "left",
                fontWeight: 600,
                color: "#00408B",
              }}
            >
              Enter 6 Digit OTP
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 3,

                justifyContent: "space-between",
              }}
            >
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 6,
                  style: {
                    width: "100%",
                    cursor: "text",
                    caretColor: "#00408B",
                    fontSize: "20px",
                    letterSpacing: "15px",
                    padding: "10px",
                  },
                }}
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  "& .MuiInputBase-input": {
                    cursor: "text",
                    width: "100%",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    width: "100%",
                    "& fieldset": {
                      borderColor: "#00408B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#00408B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#00408B",
                    },
                  },
                }}
                value={otp}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setOtp(numericValue);
                }}
              />
            </Box>

            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#00408B",
                color: "#fff",
                height: "50px",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#00408B",
                  opacity: 0.9,
                },
                "&:disabled": {
                  backgroundColor: "#00408B",
                  opacity: 0.6,
                  color: "#fff",
                },
              }}
            >
              Verify OTP
            </Button>
          </Box>
        </Box>
      </Container>
    </Slide>
  );
  const signInComp = useMemo(
    () => (
      <>
        {inputOtp && (
          <Box
            sx={{
              position: "relative",
            }}
          >
            {otpComp}
          </Box>
        )}
        {!inputOtp && mobileNumComp}
      </>
    ),
    [inputOtp, otp, mobileNo, countryCode, countdown]
  );

  return <>{signInComp}</>;
}
