import React, { useState } from "react";

import {
  Box,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import axios from "axios";
import { getItemWithExpiry } from "../utils/sessionData";
import config from "../config";

const GameConfig = () => {
  const [selectedSection, setSelectedSection] = useState<
    "none" | "game" | "localization" | "profanity"
  >("none");
  const [selectedGame, setSelectedGame] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    size: string;
  } | null>(null);
  React.useEffect(() => {
    setUploadedFile(null);
  }, [selectedGame, selectedSection]);
  const games = [
    {
      id: "eg",
      name: "Empire",
      logo: "/images/empire.png",
      displayName: "Empire Games",
    },
    {
      id: "le",
      name: "Ludo",
      logo: "/images/ludo_logo_1.png",
      displayName: "Ludo Empire",
    },
    {
      id: "sp",
      name: "SkillPatti",
      logo: "/images/skillpatti_logo_1.png",
      displayName: "Skill Patti Empire",
    },
    {
      id: "cb",
      name: "CallBreak",
      logo: "/images/callbreak_logo_1.png",
      displayName: "Callbreak Empire",
    },
    {
      id: "hc",
      name: "HandCricket",
      logo: "/images/handcricket_logo_1.png",
      displayName: "Hand Cricket Empire",
    },
    {
      id: "snl",
      name: "SnakesAndLadders",
      logo: "/images/snakesandladders_logo_1.png",
      displayName: "Snakes & Ladders Empire",
    },
    {
      id: "ml",
      name: "MegaLudo",
      logo: "/images/megaludo_logo_1.png",
      displayName: "Mega Tournament",
    },
    {
      id: "avt",
      name: "Aviator",
      logo: "/images/aviator_logo_1.png",
      displayName: "Aviator Empire",
    },
    {
      id: "re",
      name: "Rummy",
      logo: "/images/rummy_logo_1.png",
      displayName: "Rummy Empire",
    },

    {
      id: "gr",
      name: "GoalRush",
      logo: "/images/Goal Rush Empire_V1.png",
      displayName: "Goal Rush Empire",
    },
  ];
  const languages = [
    { id: "hindi", name: "Hindi", logo: "/images/hindi_11085097.png" },
    { id: "english", name: "English", logo: "/images/english_9383647.png" },
    { id: "portuguese", name: "Portuguese", logo: "/images/postu.png" },
  ];

  const [openFileSelector, { filesContent, loading: filePickerLoading }] =
    useFilePicker({
      accept: ".json",
      multiple: false,
    });

  const handleDownload = async () => {
    try {
      setLoading(true);
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      let endpoint = "";
      if (selectedSection === "game" && selectedGame) {
        endpoint = `/api/game-config?game=${selectedGame}`;
      } else if (
        selectedSection === "localization" &&
        selectedGame &&
        selectedLanguage
      ) {
        endpoint = `/api/localization?game=${selectedGame}&language=${selectedLanguage}`;
      } else if (selectedSection === "profanity") {
        endpoint = `/api/profanity`;
      } else {
        toast.error("Please select required options");
        return;
      }

      const response = await fetch(config.REST_ENDPOINT + endpoint, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const blob = new Blob([JSON.stringify(responseData, null, 2)], {
          type: "application/json",
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        let fileName = "";
        if (selectedSection === "game") {
          fileName = `${selectedGame}-config.json`;
        } else if (selectedSection === "localization") {
          fileName = `${selectedGame}-${selectedLanguage}-localization.json`;
        } else {
          fileName = "profanity-config.json";
        }

        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
        toast.success("File downloaded successfully!");
      }
    } catch (error) {
      toast.error("Download failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleUpload = async () => {
    try {
      if (
        !selectedSection ||
        (selectedSection === "localization" &&
          (!selectedGame || !selectedLanguage)) ||
        (selectedSection === "game" && !selectedGame)
      ) {
        toast.error("Please select required options first");
        return;
      }

      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = ".json";

      fileInput.onchange = async (e: any) => {
        const file = e.target.files[0];
        if (!file) return;

        setLoading(true);

        setUploadedFile({
          name: file.name,

          size: `${(file.size / 1024).toFixed(2)} KB`,
        });

        const reader = new FileReader();
        reader.onload = async (e) => {
          const fileData = JSON.parse(e.target?.result as string);

          let endpoint = "";
          if (selectedSection === "game") {
            endpoint = `/api/game-config?game=${selectedGame}`;
          } else if (selectedSection === "localization") {
            endpoint = `/api/localization?game=${selectedGame}&language=${selectedLanguage}`;
          } else if (selectedSection === "profanity") {
            endpoint = `/api/profanity`;
          }

          const response = await axios.post(
            config.REST_ENDPOINT + endpoint,
            fileData,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            toast.success("File uploaded successfully!");
          }

          setLoading(false);
        };

        reader.readAsText(file);
      };

      fileInput.click();
    } catch (error) {
      toast.error("Upload failed. Please try again.");
      setLoading(false);
    }
  };
  return (
    <Box sx={{ p: 3 }}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Typography
        variant="h4"
        sx={{ mb: 3, color: "#000000", fontWeight: "bold" }}
      >
        Configuration
      </Typography>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        {["Game", "Localization", "Profanity"].map((section) => (
          <Grid item xs={4} key={section}>
            <Button
              fullWidth
              variant={
                selectedSection === section.toLowerCase()
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                setSelectedSection(section.toLowerCase() as any);
                setSelectedGame(null);
                setSelectedLanguage(null);
              }}
              sx={{
                height: 60,
                backgroundColor:
                  selectedSection === section.toLowerCase()
                    ? "#00408B"
                    : "inherit",
                "&:hover": {
                  backgroundColor:
                    selectedSection === section.toLowerCase()
                      ? "#00408B"
                      : "inherit",
                },
                color:
                  selectedSection === section.toLowerCase()
                    ? "white"
                    : "inherit",
              }}
            >
              {section}
            </Button>
          </Grid>
        ))}
      </Grid>

      {(selectedSection === "game" || selectedSection === "localization") && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2}>
            {games.map((game) => (
              <Grid item xs={3} key={game.id}>
                <Button
                  onClick={() => setSelectedGame(game.id)}
                  sx={{
                    width: "100%",
                    height: 120,
                    backgroundColor:
                      selectedGame === game.id ? "#00408B" : "transparent",
                    color: selectedGame === game.id ? "white" : "inherit",

                    border:
                      selectedGame === game.id
                        ? "2px solid #FFD700"
                        : "1px solid #ddd",
                    "&:hover": {
                      backgroundColor:
                        selectedGame === game.id
                          ? "#00408B"
                          : "rgba(0, 64, 139, 0.1)",

                      border: "4px solid #FFD700",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    padding: "8px",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      height: "70%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={game.logo}
                      alt={game.name}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: selectedGame === game.id ? "white" : "inherit",
                    }}
                  >
                    {game.displayName}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {selectedSection === "localization" && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2}>
            {languages.map((lang) => (
              <Grid item xs={6} key={lang.id}>
                <Button
                  onClick={() => setSelectedLanguage(lang.id)}
                  sx={{
                    width: "100%",
                    height: 80,
                    backgroundColor:
                      selectedLanguage === lang.id ? "#00408B" : "transparent",
                    color: selectedLanguage === lang.id ? "white" : "inherit",
                    border:
                      selectedLanguage === lang.id
                        ? "2px solid #FFD700"
                        : "1px solid #ddd",
                    "&:hover": {
                      border: "6px solid #FFD700",
                      backgroundColor:
                        selectedLanguage === lang.id
                          ? "#00408B"
                          : "rgba(0, 64, 139, 0.1)",
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    padding: "8px",
                  }}
                >
                  <img
                    src={lang.logo}
                    alt={lang.name}
                    style={{
                      maxWidth: "60%",
                      maxHeight: "60%",
                      objectFit: "contain",
                    }}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
      {selectedSection !== "none" && (
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={handleDownload}
                disabled={loading}
                sx={{
                  width: "100%",
                  height: 80,
                  border: "1px solid #ddd",
                  "&:hover": {
                    border: "2px solid #1976d2",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img
                  src="/images/download.png"
                  alt="Download"
                  style={{
                    maxWidth: "60%",
                    maxHeight: "60%",
                    objectFit: "contain",
                  }}
                />
                <Typography variant="subtitle2" sx={{ color: "#000000" }}>
                  Download
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={handleUpload}
                disabled={loading}
                sx={{
                  width: "100%",
                  height: 80,
                  border: "1px solid #ddd",
                  "&:hover": {
                    border: "2px solid #1976d2",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img
                  src="/images/upload_10024501.png"
                  alt="Upload"
                  style={{
                    maxWidth: "60%",
                    maxHeight: "60%",
                    objectFit: "contain",
                  }}
                />
                <Typography variant="subtitle2" sx={{ color: "#000000" }}>
                  Upload
                </Typography>
              </Button>

              {uploadedFile && (
                <Box sx={{ mt: 2, p: 2, bgcolor: "#f5f5f5", borderRadius: 1 }}>
                  <Typography variant="subtitle1" color="primary">
                    Uploaded File: {uploadedFile.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Size: {uploadedFile.size}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default GameConfig;
