import React, { useState, useCallback } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
  useNotify,
} from "react-admin";
import { Stack, Typography, Button, Box } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { getItemWithExpiry } from "../utils";
import { callRestApi } from "../utils/callRestApi";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

const Withdrawals = (props: object) => {
  const { permissions } = usePermissions();
  const [verificationData, setVerificationData] = useState<Record<string, any>>(
    {}
  );
  const [accountInfoDetails, setAccountInfoDetails] = useState<
    Record<string, any>
  >({});
  const notify = useNotify();
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleAccountInfoClick = useCallback((record: any) => {
    setAccountInfoDetails((prev) => ({
      ...prev,
      [record._id]: !prev[record._id],
    }));
  }, []);
  const handleOpenDialog = (record: any) => {
    setSelectedRecord(record);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setSelectedRecord(null);
    setOpenDialog(false);
  };

  const handleVerifyClick = useCallback(async (orderId: string) => {
    try {
      const endpoint = `/payment/payout/manual-review/verify?orderId=${orderId}`;
      const response = await callRestApi(endpoint, "GET", {});

      console.log("Verification Response:", response?.data);

      setVerificationData((prevData) => ({
        ...prevData,

        [orderId]: {
          kycName: response?.data?.kycName || "",
          accountHolderName: response?.data?.accountHolderName || "",
          reason: response?.data?.reason || "",
        },
      }));
    } catch (error) {
      console.error("Error verifying names:", error);
      setVerificationData((prevData) => ({
        ...prevData,

        [orderId]: {
          kycName: "",
          accountHolderName: "",
          reason: "",
        },
      }));
    }
  }, []);
  const handleActionClick = useCallback(
    async (orderId: string, action: "approve" | "reject") => {
      try {
        const user = getItemWithExpiry("user");
        if (!user || !user.token) {
          notify("You have no access", { type: "error" });
          return;
        }

        const endpoint = `/payment/payout/manual-review/${action}?orderId=${orderId}`;
        await callRestApi(endpoint, "GET", {});

        notify(
          `${action === "approve" ? "Approved" : "Rejected"} successfully`,
          { type: "success" }
        );

        window.location.reload();
      } catch (error) {
        console.error(`Error ${action}ing payout:`, error);
        notify(`Failed to ${action} payout`, { type: "error" });
      }
    },
    [notify]
  );

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Status"
      source="status"
      choices={[
        { id: "success", name: "Success" },
        { id: "failed", name: "Failed" },
        { id: "pending", name: "Pending" },
        { id: "refund", name: "Refund" },
        { id: "manualReview", name: "Manual Review" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by Gateway"
      source="gateway"
      choices={[
        { id: "juspay", name: "Juspay" },
        { id: "cashfree", name: "Cashfree" },
        { id: "ebanx", name: "Ebanx" },
      ]}
    />,
    <SelectArrayInput
      label="Filter by PayoutType"
      source="payoutType"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "IMPS", name: "IMPS" },
        { id: "PIX", name: "PIX" },
      ]}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Withdrawals</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <TextField source="_id" label="Beneficiary Id" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="gateway" />
          <TextField source="payoutType" />
          <FunctionField
            source="amount"
            label="Amount"
            render={(record: any) => `₹${record.amount}`}
          />
          <DateField source="createdAt" showTime label="Created at" />
          {/* <TextField source="settledAmount" label="Settled Amount" /> */}
          <FunctionField
            label="Account Info"
            render={(record: any) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src="/images/accountdetails.png"
                  alt="Account Details"
                  style={{
                    width: "35px",
                    height: "35px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleOpenDialog(record)}
                />
                <span style={{ fontSize: "12px" }}>Ac/Dt</span>
              </div>
            )}
          />
          // Add this Dialog component at the bottom of your return statement
          before the closing List tag:
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="xs"
            PaperProps={{
              sx: {
                width: "60%",
                minHeight: "150px",
                maxHeight: "300px",
              },
            }}
          >
            <DialogTitle>Account Information</DialogTitle>
            <DialogContent>
              {selectedRecord?.accountInfo?.imps && (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    IMPS Details:
                  </Typography>
                  <Typography>
                    Account: {selectedRecord.accountInfo.imps.accountNumber}
                  </Typography>
                  <Typography>
                    IFSC: {selectedRecord.accountInfo.imps.ifsc}
                  </Typography>
                </Box>
              )}
              {selectedRecord?.accountInfo?.accountNumber && (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Account Details:
                  </Typography>
                  <Typography>
                    Account: {selectedRecord.accountInfo.accountNumber}
                  </Typography>
                  <Typography>
                    IFSC: {selectedRecord.accountInfo.ifscCode}
                  </Typography>
                </Box>
              )}
              {selectedRecord?.accountInfo?.upi && (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    UPI Details:
                  </Typography>
                  <Typography>
                    ID: {selectedRecord.accountInfo.upi.upiId}
                  </Typography>
                </Box>
              )}
              {selectedRecord?.accountInfo?.upiId && (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    UPI Details:
                  </Typography>
                  <Typography>
                    ID: {selectedRecord.accountInfo.upiId}
                  </Typography>
                </Box>
              )}
              {selectedRecord?.accountInfo?.pix && (
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    PIX Details:
                  </Typography>
                  <Typography>
                    Document: {selectedRecord.accountInfo.pix.documentNumber}
                  </Typography>
                  <Typography>
                    Bank: {selectedRecord.accountInfo.pix.bankInfo.bankName}
                  </Typography>
                  <Typography>
                    Branch: {selectedRecord.accountInfo.pix.bankInfo.bankBranch}
                  </Typography>
                  <Typography>
                    Account:{" "}
                    {selectedRecord.accountInfo.pix.bankInfo.bankAccount}
                  </Typography>
                  <Typography>
                    Type: {selectedRecord.accountInfo.pix.bankInfo.accountType}
                  </Typography>
                </Box>
              )}
            </DialogContent>
          </Dialog>
          <FunctionField
            label="Status"
            render={(record: any) => {
              const statusConfig = {
                success: {
                  icon: "/images/success.png",
                  label: "Success",
                },
                failed: {
                  icon: "/images/failed.png",
                  label: "Failed",
                },
                pending: {
                  icon: "/images/pending.png",
                  label: "Pending",
                },
                refund: {
                  icon: "/images/refund.png",
                  label: "Refund",
                },
                manualReview: {
                  icon: "/images/manual_review.png",
                  label: "Review",
                },
              };

              const config =
                statusConfig[record.status as keyof typeof statusConfig];

              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={config.icon}
                    alt={config.label}
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                  />
                  <span style={{ fontSize: "12px" }}>{config.label}</span>
                </div>
              );
            }}
          />
          <FunctionField
            label="Actions"
            render={(record: any) =>
              record.status === "manualReview" ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/verify.png"
                      alt="Verify"
                      style={{
                        width: "35px",
                        height: "35px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleVerifyClick(record.orderId)}
                    />
                    <span style={{ fontSize: "12px" }}>Verify</span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/approve.png"
                      alt="Approve"
                      style={{
                        width: "35px",
                        height: "35px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleActionClick(record.orderId, "approve")
                      }
                    />
                    <span style={{ fontSize: "12px" }}>Approve</span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/reject.png"
                      alt="Reject"
                      style={{
                        width: "35px",
                        height: "35px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleActionClick(record.orderId, "reject")
                      }
                    />
                    <span style={{ fontSize: "12px" }}>Reject</span>
                  </div>
                </Box>
              ) : (
                "N/A"
              )
            }
          />
          <FunctionField
            label="KYC Name"
            render={(record: any) =>
              record.status === "manualReview"
                ? verificationData[record.orderId]?.kycName || ""
                : ""
            }
          />
          <FunctionField
            label="Account Holder Name"
            render={(record: any) =>
              record.status === "manualReview"
                ? verificationData[record.orderId]?.accountHolderName || ""
                : ""
            }
          />
          <FunctionField
            label="Reason"
            render={(record: any) =>
              record.status === "manualReview"
                ? verificationData[record.orderId]?.reason || ""
                : ""
            }
          />
          <DateField source="createdAt" showTime label="Created at" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Withdrawals;
