import React, { useState } from "react";
import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  WrapperField,
  ShowButton,
  FunctionField,
  Button as RaButton,
  useRefresh,
  usePermissions,
  DatagridConfigurable,
  FilterForm,
  DateTimeInput,
  FilterButton,
  useListContext,
  SelectArrayInput,
} from "react-admin";

import { callRestApi } from "../../utils/callRestApi";
import { toast } from "react-toastify";
import { Stack } from "rsuite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Button as MuiButton,
  Typography,
} from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { makeStyles } from "@mui/styles";

interface AccountResponseItem {
  orderId: string;
  status: string;
  accountInfo?: {
    imps?: {
      accountNumber: string;
      ifsc: string;
    };
    upi?: {
      upiId: string;
    };
    pix?: {
      documentNumber: string;
      bankInfo: {
        bankName: string;
        bankBranch: string;
        bankAccount: string;
        accountType: string;
      }
    };
    document?: string;
    accountNumber?: string;
    ifscCode?: string;
    upiId?: string;
  };
}




interface AccountDetails {
  upiIds: string[];
  bankAccounts: Array<{ accountNumber: string; ifscCode: string }>;
}
const countryToCodeMap: { [key: string]: string } = {
  India: "in",
  Pakistan: "pk",
  Bangladesh: "bd",
  Uzbekistan: "uz",
  Myanmar: "mm",
  Tunisia: "tn",
  Brazil: "br",
  Mexico: "mx",
  Cambodia: "kh",
  Nepal: "np",
  Argentina: "ar",
  "Bolivia, Plurinational State of": "bo",
  Chile: "cl",
  Colombia: "co",
  "Costa Rica": "cr",
  "Dominican Republic": "do",
  Ecuador: "ec",
  Egypt: "eg",
  "El Salvador": "sv",
  Ghana: "gh",
  Guatemala: "gt",
  Nigeria: "ng",
  Panama: "pa",
  Paraguay: "py",
  Peru: "pe",
  "South Africa": "za",
  Tanzania: "tz",
  Uganda: "ug",
  Uruguay: "uy",
  Zambia: "zm",
  Kenya: "ke",
};
const getCountryFlagURL = (country: string) => {
  const code = countryToCodeMap[country];
  return `https://flagcdn.com/w40/${code}.png`;
};
const Users = (props: object) => {
  const refresh = useRefresh();

  const [userData, setUserData] = useState<{
    _id: string;
    isBlocked: boolean;
    isWarning: boolean;
  } | null>(null);

  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [accountDetails, setAccountDetails] = useState<{
    upiIds: string[];
    accountNumbers: string[];
    ifscCodes: string[];
  } | null>(null);

  const [actionType, setActionType] = useState<"block" | "warning">("block");

  const handleOpenModal = (record: any, action: "block" | "warning") => {
    setUserData({
      _id: record.id,
      isBlocked: record.isBlocked,
      isWarning: record.warning?.isWarned ?? false,
    });
    setActionType(action);
    setOpen(true);
    if (action === "block") setReason("");
  };

  const handleCloseModal = () => {
    setOpen(false);
    setReason("");
  };

  const handleBlock = async () => {
    try {
      if (!permissions.includes("admin")) {
        toast.error("You don't have access to this functionality");
        return;
      }

      if (userData) {
        const endpoint = "/users/change-block-status";
        const url = `${endpoint}`;
        await callRestApi(url, "PATCH", {
          userId: userData._id,
          shouldBlock: !userData.isBlocked,
          reason,
          isWarning: userData.isWarning,
        });

        refresh();
        toast.success(
          !userData.isBlocked
            ? "Blocked User Successfully!"
            : "Unblocked User Successfully!"
        );
        handleCloseModal();
      } else {
        toast.error("No user data available");
      }
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  const handleWarning = async () => {
    try {
      if (!permissions.includes("admin")) {
        toast.error("You don't have access to this functionality");
        return;
      }

      if (userData) {
        const endpoint = "/users/change-block-status";
        const url = `${endpoint}`;

        const newIsWarned = !userData.isWarning;

        await callRestApi(url, "PATCH", {
          userId: userData._id,
          shouldBlock: newIsWarned,
          reason: "N/A",
          isWarning: true,
        });

        setUserData({
          ...userData,
          isWarning: newIsWarned,
        });

        refresh();
        toast.success(
          newIsWarned
            ? "Warning Added Successfully!"
            : "Warning Removed Successfully!"
        );
        setOpen(false);
      } else {
        toast.error("No user data available");
      }
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  const handleDeleteKYC = async (record: { _id: string }) => {
    try {
      setLoading(true);
      const userId = record._id;
      const url = "/users/delete-kyc";

      const { data, status } = (await callRestApi(url, "DELETE", {
        userId,
      })) as AxiosResponse;

      if (status === 200) {
        toast("KYC Deleted Successfully", {
          type: "success",
        });
      } else {
        throw new Error(data.message || "Unexpected response from server");
      }
    } catch (error: unknown) {
      console.error("Delete KYC error:", error);

      if (error instanceof AxiosError) {
        if (
          error.response?.status === 400 &&
          error.response.data?.message === "KYC doesn't exist"
        ) {
          toast(" KYC Doesn't exist in DB.", {
            type: "info",
          });
        } else {
          toast(error.response?.data?.message || "Failed to delete KYC", {
            type: "error",
          });
        }
      } else if (error instanceof Error) {
        toast(error.message, { type: "error" });
      } else {
        toast("An unknown error occurred", { type: "error" });
      }
    } finally {
      setDeleteConfirmModalOpen(false);
      setLoading(false);
      refresh();
    }
  };
  const useStyles = makeStyles({
    selectInput: {
      maxHeight: "00px",
      overflowY: "auto",
      width: "50px",
    },
  });
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Country"
      source="address.country"
      choices={[
        { id: "India", name: "India" },
        { id: "Bangladesh", name: "Bangladesh" },
        { id: "Uzbekistan", name: "Uzbekistandia" },
        { id: "Tunisia", name: "Tunisia" },
        { id: "Nigeria", name: "Nigeria" },
        { id: "Nepal", name: "Nepal" },
        { id: "Myanmar", name: "Myanmar" },
        { id: "Mexico", name: "Mexico" },
        { id: "Kenya", name: "Kenya" },
        { id: "Costa Rica", name: "Costa Rica" },
        { id: "Colombia", name: "Colombia" },
        { id: "Bolivia, Plurinational State of", name: "Bolivia" },
        { id: "Brazil", name: "Brazil" },
        { id: "Cambodia", name: "Cambodia" },
        { id: "Argentina", name: "Argentina" },
        { id: "Pakistan", name: "Pakistan" },
        { id: "Chile", name: "Chile" },
        { id: "South Africa", name: "South Africa" },
        { id: "Guatemala", name: "Guatemala" },
        { id: "El Salvador", name: "El Salvador" },
        { id: "Egypt", name: "Egypt" },
        { id: "Uganda", name: "Uganda" },
        { id: "Zambia", name: "Zambia" },
        { id: "Ecuador", name: "Ecuador" },
        { id: "Ghana", name: "Ghana" },
        { id: "Panama", name: "Panama" },
        { id: "Tanzania, United Republic of", name: "Tanzania" },
        { id: "United States of America", name: "United States of America" },
        { id: "Peru", name: "Peru" },
        { id: "Paraguay", name: "Paraguay" },
        { id: "Uruguay", name: "Uruguay" },
      ]}
      style={{
        maxHeight: "50px",
        overflowY: "auto",
        width: "50px",
      }}
    />,
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Name"
        source="name"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Mobile Number"
        source="mobileNumber.number"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search App Version"
        source="build.appVersion"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search App Code"
        source="build.appCode"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const formatWalletValue = (value: any) => {
    if (!isNaN(parseFloat(value))) {
      const stringValue = String(value);
      const decimalIndex = stringValue.indexOf(".");
      if (decimalIndex !== -1) {
        return stringValue.slice(0, decimalIndex + 3);
      } else {
        return stringValue;
      }
    } else {
      return "";
    }
  };

  const handleAccountDetails = async (record: any) => {
    try {
      const userId = record._id;
      const url = `/api/payment/account-details?count=100&filter={"userId":"${userId}"}&key=ttYXm2U4V1Qwah5893ewp78494XP19IZMl&skip=0&sortBy=createdAt&sortDir=-1`;
      const response = await callRestApi(url, "GET");

      if (response && response.data && response.data.items.length > 0) {
        const items: AccountResponseItem[] = response.data.items;

        const accountDetailsMap = new Map<string, string>();
        const upiIdsSet = new Set<string>();

        items.forEach((item) => {
         
          if (item.accountInfo?.imps?.accountNumber) {
            if (!accountDetailsMap.has(item.accountInfo.imps.accountNumber)) {
              accountDetailsMap.set(
                item.accountInfo.imps.accountNumber,
                item.accountInfo.imps.ifsc || ""
              );
            }
          }
          if (item.accountInfo?.pix?.bankInfo) {
            const pixAccount = item.accountInfo.pix.bankInfo.bankAccount;
            if (!accountDetailsMap.has(pixAccount)) {
              accountDetailsMap.set(
                pixAccount,
                ` Bank: ${item.accountInfo.pix.bankInfo.bankName} /Branch: ${item.accountInfo.pix.bankInfo.bankBranch} / Doc: ${item.accountInfo.pix.documentNumber} / AccountType: ${item.accountInfo.pix.bankInfo.accountType}`
              );
            }
          }
          
          if (item.accountInfo?.accountNumber) {
            if (!accountDetailsMap.has(item.accountInfo.accountNumber)) {
              accountDetailsMap.set(
                item.accountInfo.accountNumber,
                item.accountInfo.ifscCode || ""
              );
            }
          }

          if (item.accountInfo?.upi?.upiId) {
            upiIdsSet.add(item.accountInfo.upi.upiId);
          }
          if (item.accountInfo?.upiId) {
            upiIdsSet.add(item.accountInfo.upiId);
          }
        });

        setAccountDetails({
          upiIds: Array.from(upiIdsSet),
          accountNumbers: Array.from(accountDetailsMap.keys()),
          ifscCodes: Array.from(accountDetailsMap.values()),
        });

        setOpenAccountModal(true);
      } else {
        toast.error("No account details found.");
        setAccountDetails(null);
      }
    } catch (error) {
      console.error("Failed to fetch account details:", error);
      toast.error(
        "Failed to fetch account details. Please check the console for more details."
      );
    }
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Users List</h2>

      <List
        {...props}
        actions={<ListActions />}
        filters={<UserNameFilter />}
        sort={{ field: "_id", order: "DESC" }}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="username" label="User Name" />
          <TextField source="name" label="Name" />
          <FunctionField
            source="wallet.main"
            label="Main Wallet"
            render={(record: any) =>
              `₹${formatWalletValue(record.wallet.main)}`
            }
          />

          <FunctionField
            source="wallet.win"
            label="Win Wallet"
            render={(record: any) => `₹${formatWalletValue(record.wallet.win)}`}
          />

          <FunctionField
            source="wallet.bonus"
            label="Bonus Wallet"
            render={(record: any) =>
              `₹${formatWalletValue(record.wallet.bonus)}`
            }
          />

          <TextField source="build.appVersion" label="App Version" />
          <TextField source="build.appCode" label="App Code" />
          <FunctionField
            label="Country"
            render={(record: any) => {
              const country = record.address?.country;
              const flagURL = getCountryFlagURL(country);

              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={flagURL}
                    alt={`${country} Flag`}
                    style={{
                      width: "30px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                    onError={(e) =>
                      (e.currentTarget.src =
                        "https://via.placeholder.com/30x20")
                    }
                  />
                  <span>{country}</span>
                </div>
              );
            }}
          />

          <DateField source="createdAt" showTime label="Registered At" />
          {permissions.includes("admin") && (
            <FunctionField
              label="Actions"
              render={(record: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Warn/Unwarn */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/images/warnunwarn.png"
                        alt="Warn/Unwarn"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenModal(record, "warning")}
                      />
                      <span style={{ fontSize: "12px" }}>
                        {!record.warning?.isWarned ? "Warn" : "UnWarn"}
                      </span>
                    </div>

                    {/* Block/Unblock */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/images/blockunblck.png"
                        alt="Block/Unblock"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenModal(record, "block")}
                      />
                      <span style={{ fontSize: "12px" }}>
                        {!record.isBlocked ? "Block" : "Unblock"}
                      </span>
                    </div>

                    {/* Account Details */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/images/accountdetails.png"
                        alt="Account Details"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleAccountDetails(record)}
                      />
                      <span style={{ fontSize: "12px" }}>Ac/Dt</span>
                    </div>

                    {/* Delete KYC */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/images/delete kyc.png"
                        alt="Delete KYC"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteKYC(record)}
                      />
                      <span style={{ fontSize: "12px" }}>Del/KYC</span>
                    </div>
                  </div>
                );
              }}
            />
          )}
          <ShowButton
            icon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/show.png"
                  alt="Show Details"
                  style={{ width: "30px", height: "30px" }}
                />
                <span
                  style={{
                    fontSize: "12px",
                    marginTop: "4px",
                    color: "#000000",
                  }}
                >
                  Show
                </span>
              </div>
            }
            label=""
          />
        </DatagridConfigurable>
      </List>

      {/* Modal for Block/Unblock User */}
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>
          {actionType === "block" ? "Block/Unblock User" : "Add/Remove Warning"}
        </DialogTitle>
        <DialogContent>
          {actionType === "block" && (
            <>
              <Typography>Reason for Block/Unblock:</Typography>
              <MuiTextField
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {actionType === "block" && (
            <MuiButton onClick={handleBlock} color="primary">
              {userData?.isBlocked ? "Unblock" : "Block"}
            </MuiButton>
          )}
          {actionType === "warning" && (
            <MuiButton onClick={handleWarning} color="primary">
              {userData?.isWarning ? "Remove Warning" : "Add Warning"}
            </MuiButton>
          )}
          <MuiButton onClick={handleCloseModal} color="primary">
            Cancel
          </MuiButton>
        </DialogActions>
      </Dialog>

      {/* Modal for Account Details */}
      <Dialog
        open={openAccountModal}
        onClose={() => setOpenAccountModal(false)}
      >
        <DialogTitle>Account Details</DialogTitle>
        <DialogContent>
          {accountDetails ? (
            <div>
              {accountDetails.upiIds.length > 0 && (
                <>
                  <Typography variant="h6">UPI IDs:</Typography>
                  {accountDetails.upiIds.map((upiId, index) => (
                    <Typography key={index}>{upiId}</Typography>
                  ))}
                </>
              )}
              {accountDetails.accountNumbers.length > 0 &&
                accountDetails.ifscCodes.length > 0 && (
                  <>
                    <Typography variant="h6">Bank Account Details:</Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ width: "100%" }}
                    >
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              Account Number
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              IFSC Code
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountDetails.accountNumbers.map(
                            (accountNo, index) => {
                              const ifscCode = accountDetails.ifscCodes[index];
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      padding: "8px",
                                      borderBottom: "1px solid #ddd",
                                    }}
                                  >
                                    {accountNo}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      borderBottom: "1px solid #ddd",
                                    }}
                                  >
                                    {ifscCode}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </Stack>
                  </>
                )}
              {accountDetails.upiIds.length === 0 &&
                accountDetails.accountNumbers.length === 0 &&
                accountDetails.ifscCodes.length === 0 && (
                  <Typography>No account details available.</Typography>
                )}
            </div>
          ) : (
            <Typography>No account details available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setOpenAccountModal(false)} color="primary">
            Close
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
