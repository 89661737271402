import { useState } from "react";
import Box from "@mui/material/Box";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BarChartIcon from "@material-ui/icons/BarChart";
import CreateIcon from "@mui/icons-material/Create";
import PagesIcon from "@mui/icons-material/Pages";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CasinoIcon from "@mui/icons-material/Casino";
import GridViewIcon from "@mui/icons-material/GridView";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

import {
  PersonAdd,
  People,
  Feedback,
  BugReport,
  CreditCard,
  Fingerprint,
  HourglassTop,
  AddCard,
  Rule,
  DashboardCustomizeRounded,
  VideogameAsset,
  Security,
  DashboardOutlined,
  List,
  CheckBox,
  AttachMoney,
  Block,
  AppBlocking,
  AdminPanelSettings,
  CardGiftcardRounded,
  CardGiftcard,
  SystemSecurityUpdateGood,
  Android,
  Campaign,
  InsertChart,
} from "@mui/icons-material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import SubMenu from "./SubMenu";
import StyleIcon from "@mui/icons-material/Style";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
const ScrollableBox = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 64px)",
  overflowY: "auto",
  overflowX: "visible",
  backgroundColor: "#ffffff",
  border: "2px solid #e0e0e0",

  "& .MuiTypography-root": {
    color: "#000000",
  },

  "& .MuiList-root": {
    backgroundColor: "#ffffff",
  },

  "& .MuiListItem-root": {
    backgroundColor: "#ffffff",
    transition: "background-color 0.2s ease",
    "&.Mui-selected": {
      backgroundColor: "#00408B",
      borderLeft: "4px solid #00408B",
      "& .MuiTypography-root": {
        color: "#ffffff",
      },
      "& .MuiListItemIcon-root": {
        color: "#ffffff",
      },
    },
    "&:hover": {
      backgroundColor: "#00408B",
      "& .MuiTypography-root": {
        color: "#ffffff",
      },
      "& .MuiListItemIcon-root": {
        color: "#ffffff",
      },
    },
  },

  "& .MuiCollapse-root": {
    backgroundColor: "#ffffff",
  },

  "& .RaMenuItemLink-root": {
    backgroundColor: "#ffffff",
    transition: "background-color 0.2s ease",
    "&.RaMenuItemLink-active": {
      backgroundColor: "#00408B",
      borderLeft: "4px solid #00408B",
      "& .MuiTypography-root": {
        color: "#ffffff",
      },
      "& .MuiListItemIcon-root": {
        color: "#ffffff",
      },
    },
    "&:hover": {
      backgroundColor: "#00408B",
      "& .MuiTypography-root": {
        color: "#ffffff",
      },
      "& .MuiListItemIcon-root": {
        color: "#ffffff",
      },
    },
  },

  "& .MuiListItemText-root": {
    color: "#000000",
  },

  "& .MuiListItemIcon-root": {
    color: "#000000",
  },

  "& .MuiMenuItem-root": {
    backgroundColor: "#ffffff",
  },

  "& .RaMenu-root": {
    backgroundColor: "#ffffff",
  },

  "& .SubMenu-root": {
    backgroundColor: "#ffffff",
  },

  "& .MuiButtonBase-root": {
    backgroundColor: "#ffffff",
  },

  "& .MuiPaper-root": {
    backgroundColor: "#ffffff",
  },

  "&::-webkit-scrollbar": {
    width: "6px",
  },

  "&::-webkit-scrollbar-track": {
    background: "#ffffff",
    borderRadius: "3px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#00408B",
    borderRadius: "3px",
    "&:hover": {
      background: "#003072",
    },
  },
}));

type MenuName =
  | "payoutMenu"
  | "withdrawalsMenu"
  | "kycMenu"
  | "gameMenu"
  | "usersMenu"
  | "gameRecords"
  | "couponMenu"
  | "TournamentMenu"
  | "MegaTournamentMenu"
  | "userMenu"
  | "spGameMenu"
  | "rummyGameMenu"
  | "cbGameMenu"
  | "snlGameMenu"
  | "eplGameMenu"
  | "avtGameMenu"
  | "transactionsMenu"
  | "ludoGameMenu"
  | "GlobalMenu"
  | "depositsMenu";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    payoutMenu: true,
    gameMenu: true,
    usersMenu: true,
    kycMenu: true,
    userMenu: true,
    gameRecords: true,
    withdrawalsMenu: true,
    couponMenu: true,
    TournamentMenu: true,
    MegaTournamentMenu: true,
    spGameMenu: true,
    rummyGameMenu: true,
    cbGameMenu: true,
    snlGameMenu: true,
    eplGameMenu: true,
    ludoGameMenu: true,
    transactionsMenu: true,
    depositsMenu: true,
    GlobalMenu: true,
    avtGameMenu: true,
  });

  const { permissions } = usePermissions();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <ScrollableBox
      sx={{
        width: open ? 320 : 50,
        position: "relative",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      {permissions.includes("developer") && (
        <>
          <MenuItemLink
            to="/api/users/appVersions/dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Versions Dashboard`}
            leftIcon={<Android />}
            dense={dense}
          />
        </>
      )}
      {permissions.includes("finance") && (
        <>
          <MenuItemLink
            to="/api/payment/revenue-dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Revenue Dashboard`}
            leftIcon={<CurrencyRupeeIcon />}
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle("usersMenu")}
            isOpen={state.usersMenu}
            name="Users"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/users/stats/counts"
              state={{ _scrollToTop: true }}
              primaryText={`Users Stats`}
              leftIcon={<InsertChart />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games Details"
            icon={<SportsEsportsIcon />}
            dense={dense}
          >
            <SubMenu
              handleToggle={() => handleToggle("ludoGameMenu")}
              isOpen={state.ludoGameMenu}
              name="Ludo Empire"
              icon={<CasinoIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/game-table/ludo-history"
                state={{ _scrollToTop: true }}
                primaryText={`Ludo History`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/users/revenue/report"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<ShowChartIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("snlGameMenu")}
              isOpen={state.snlGameMenu}
              name="SnakeAndLadder"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/snl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/snakeandladder-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("eplGameMenu")}
              isOpen={state.eplGameMenu}
              name="HandCricket"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/hancricket-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/epl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("avtGameMenu")}
              isOpen={state.eplGameMenu}
              name="Aviator"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/aviator-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/aviator-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("spGameMenu")}
              isOpen={state.spGameMenu}
              name="Skillpatti Empire"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/sp/dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />

              <MenuItemLink
                to="/api/sp-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("rummyGameMenu")}
              isOpen={state.rummyGameMenu}
              name="Rummy"
              icon={<SportsBaseballIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/rummy/dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Rummy Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/re-round-history"
                state={{ _scrollToTop: true }}
                primaryText={`Rummy Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("cbGameMenu")}
              isOpen={state.cbGameMenu}
              name="Callbreak Empire"
              icon={<VideogameAsset />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/callbreak-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={`Game Dashboard`}
                leftIcon={<GridViewIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/cbr-game-history"
                state={{ _scrollToTop: true }}
                primaryText={`Game Histories`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("TournamentMenu")}
              isOpen={state.TournamentMenu}
              name="Tournament"
              icon={<PagesIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/api/mega-tournament"
                state={{ _scrollToTop: true }}
                primaryText={`Mega Tournament History`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
              <MenuItemLink
                to="/api/mega-tournament/dashboard/stats"
                state={{ _scrollToTop: true }}
                primaryText={`Mega Tournament Dashboard`}
                leftIcon={<HistoryToggleOffIcon />}
                dense={dense}
              />
            </SubMenu>
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("transactionsMenu")}
            isOpen={state.transactionsMenu}
            name="Transactions"
            icon={<CreditCard />}
            dense={dense}
          >
            <MenuItemLink
              to="/api/payment/deposit"
              state={{ _scrollToTop: true }}
              primaryText="Deposits"
              leftIcon={
                <img
                  src="/images/Deposit.png"
                  alt="Deposit"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Withdrawals"
              leftIcon={<PaymentsIcon />}
              dense={dense}
              title="withdrawals"
            />
            <MenuItemLink
              to="/api/payment/convert"
              state={{ _scrollToTop: true }}
              primaryText="Converts"
              leftIcon={<AttachMoney />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/admin-transaction"
              state={{ _scrollToTop: true }}
              primaryText={`Admin Transactions`}
              leftIcon={<AdminPanelSettings />}
              dense={dense}
            />
            <MenuItemLink
              to="api/payment/dashboard"
              state={{ _scrollToTop: true }}
              primaryText={`Payment Dashboard`}
              leftIcon={<DashboardCustomizeRounded />}
              dense={dense}
            />
            <MenuItemLink
              to="/api/payment/tax-deductions"
              state={{ _scrollToTop: true }}
              primaryText={`TDS Details`}
              leftIcon={<AttachMoney />}
              dense={dense}
            />
          </SubMenu>
        </>
      )}
      {permissions.includes("support") && (
        <>
           <MenuItemLink
              to="/api/users/stats/counts"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  {`Users Stats`}
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/stats.png"
                  alt="Stats"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
           <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games Details"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/game-controller_1401989.png"
                  alt="Game Details"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.gameMenu ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
             <SubMenu
              handleToggle={() => handleToggle("ludoGameMenu")}
              isOpen={state.ludoGameMenu}
              name="Ludo Empire"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/ludo_logo_1.png"
                    alt="Ludo"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.ludoGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
             <MenuItemLink
                to="/api/game-table/ludo-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Ludo History`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("snlGameMenu")}
              isOpen={state.snlGameMenu}
              name="SnakeAndLadder"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/snakesandladders_logo_1.png"
                    alt="Snake and Ladders"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.snlGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
            <MenuItemLink
                to="/api/snl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("eplGameMenu")}
              isOpen={state.eplGameMenu}
              name="HandCricket"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/handcricket_logo_1.png"
                    alt="Hand Cricket"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.eplGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/epl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
 <SubMenu
              handleToggle={() => handleToggle("avtGameMenu")}
              isOpen={state.avtGameMenu}
              name="Aviator"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/aviator_logo_1.png"
                    alt="Aviator"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.avtGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
               <MenuItemLink
                to="/api/aviator-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              </SubMenu>
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("spGameMenu")}
              isOpen={state.spGameMenu}
              name="Skillpatti Empire"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/skillpatti_logo_1.png"
                    alt="Skillpatti"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.spGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
             <MenuItemLink
                to="/api/sp-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("cbGameMenu")}
              isOpen={state.cbGameMenu}
              name="Callbreak Empire"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/callbreak_logo_1.png"
                    alt="Callbreak"
                    width="40"
                    height="40"
                  />

                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.cbGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
             <MenuItemLink
                to="/api/cbr-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("rummyGameMenu")}
              isOpen={state.rummyGameMenu}
              name="Rummy"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/rummy_logo_1.png"
                    alt="Rummy"
                    width="60"
                    height="60"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.rummyGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
                <MenuItemLink
                to="/api/re-round-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
          </SubMenu>

        
          <SubMenu
            handleToggle={() => handleToggle("transactionsMenu")}
            isOpen={state.transactionsMenu}
            name="Transactions"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/data-transfer_6337624.png"
                  alt="Transactions"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.transactionsMenu
                      ? "rotate(180deg)"
                      : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
             <MenuItemLink
              to="/api/payment/deposit"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  Deposits
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/deposit.png"
                  alt="Deposit"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/withdrawal"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  Withdrawals
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/withdrawals.png"
                  alt="Withdrawal"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
              title="withdrawals"
            />
            <MenuItemLink
              to="/api/payment/convert"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  Transfers
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/converts.png"
                  alt="Transfer"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/transactions/referral-transaction"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Referral Transactions`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/referraal.png"
                  alt="Referral"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/bonus-transaction"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Bonus Transactions`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/bonus_9587115.png"
                  alt="Bonus"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
          </SubMenu>
        </>
      )}

      {permissions.includes("admin") && (
        <>
          <MenuItemLink
            to="/api/payment/revenue-dashboard"
            state={{ _scrollToTop: true }}
            primaryText={
              <Typography
                sx={{ fontWeight: "bold", color: "#000000" }}
              >{`Revenue Dashboard`}</Typography>
            }
            leftIcon={
              <img
                src="/images/money-growth_7736461.png"
                alt="Revenue Dashboard"
                width="24"
                height="24"
              />
            }
            dense={dense}
          />

          <MenuItemLink
            to="/api/users/appVersions/dashboard"
            state={{ _scrollToTop: true }}
            primaryText={
              <Typography
                sx={{ fontWeight: "bold", color: "#000000" }}
              >{`Versions Dashboard`}</Typography>
            }
            leftIcon={
              <img
                src="/images/operative-system_3550395.png"
                alt="Version Dashboard"
                width="24"
                height="24"
              />
            }
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle("usersMenu")}
            isOpen={state.usersMenu}
            name="Users"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/group_11042479.png"
                  alt="Users"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.usersMenu ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
            <MenuItemLink
              to="/api/users/details" 
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  {`Users Details`}
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/friendlist_6863707.png"
                  alt="Users List"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/users/stats/counts"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  {`Users Stats`}
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/stats.png"
                  alt="Stats"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games Details"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/game-controller_1401989.png"
                  alt="Game Details"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.gameMenu ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
            <SubMenu
              handleToggle={() => handleToggle("ludoGameMenu")}
              isOpen={state.ludoGameMenu}
              name="Ludo Empire"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/ludo_logo_1.png"
                    alt="Ludo"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.ludoGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/users/revenue/report/ludo" 
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                    {`Ludo Dashboard`}
                  </Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/api/game-table/ludo-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Ludo History`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("snlGameMenu")}
              isOpen={state.snlGameMenu}
              name="SnakeAndLadder"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/snakesandladders_logo_1.png"
                    alt="Snake and Ladders"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.snlGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/snakeandladder-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/api/snl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="admin/live-games/snakesandladders"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Live Games`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/live_3049319.png"
                    alt="Live Games"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("eplGameMenu")}
              isOpen={state.eplGameMenu}
              name="HandCricket"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/handcricket_logo_1.png"
                    alt="Hand Cricket"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.eplGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/hancricket-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="/api/epl-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="admin/live-games/handcricket"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Live Games`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/live_3049319.png"
                    alt="Live Games"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("avtGameMenu")}
              isOpen={state.avtGameMenu}
              name="Aviator"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/aviator_logo_1.png"
                    alt="Aviator"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.avtGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/aviator-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="/api/aviator-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="admin/live-games/aviator"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Live Games`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/live_3049319.png"
                    alt="Live Games"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("spGameMenu")}
              isOpen={state.spGameMenu}
              name="Skillpatti Empire"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/skillpatti_logo_1.png"
                    alt="Skillpatti"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.spGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/sp/dashboard"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/api/sp-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="admin/live-games/skillpatti"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Live Games`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/live_3049319.png"
                    alt="Live Games"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("cbGameMenu")}
              isOpen={state.cbGameMenu}
              name="Callbreak Empire"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/callbreak_logo_1.png"
                    alt="Callbreak"
                    width="40"
                    height="40"
                  />

                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.cbGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/callbreak-dashboard"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="/api/cbr-game-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="admin/live-games/callbreak"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Live Games`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/live_3049319.png"
                    alt="Live Games"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("rummyGameMenu")}
              isOpen={state.rummyGameMenu}
              name="Rummy"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/rummy_logo_1.png"
                    alt="Rummy"
                    width="60"
                    height="60"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.rummyGameMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/api/rummy/dashboard"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/api/re-round-history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Game Histories`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="admin/live-games/rummyempire"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Live Games`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/live_3049319.png"
                    alt="Live Games"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>

            <SubMenu
              handleToggle={() => handleToggle("TournamentMenu")}
              isOpen={state.TournamentMenu}
              name="Tournament"
              icon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/images/megaludo_logo_1.png"
                    alt="Mega Ludo"
                    width="40"
                    height="40"
                  />
                  <KeyboardArrowDown
                    sx={{
                      ml: 1,
                      transform: state.TournamentMenu
                        ? "rotate(180deg)"
                        : "rotate(0)",
                      transition: "transform 0.2s",
                    }}
                  />
                </Box>
              }
              dense={dense}
            >
              <MenuItemLink
                to="/ludo/tournament"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Create Tournament`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/createe.png"
                    alt="Create Tournament"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/api/tournament"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Tournament Dashboard`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/ludo/mega-tournament"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography
                    sx={{ fontWeight: "bold", color: "#000000" }}
                  >{`Create Mega Tournament`}</Typography>
                }
                leftIcon={
                  <img
                    src="/images/createe.png"
                    alt="Create Tournament"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
              <MenuItemLink
                to="/api/mega-tournament/history"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                    {`Mega Tournament History`}
                  </Typography>
                }
                leftIcon={
                  <img
                    src="/images/contract_16632018.png"
                    alt="Game History"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />

              <MenuItemLink
                to="/api/mega-tournament/dashboard/stats"
                state={{ _scrollToTop: true }}
                primaryText={
                  <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                    {`Mega Tournament Dashboard`}
                  </Typography>
                }
                leftIcon={
                  <img
                    src="/images/dashboard_7663921.png"
                    alt="Game Dashboard"
                    width="24"
                    height="24"
                  />
                }
                dense={dense}
              />
            </SubMenu>
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("transactionsMenu")}
            isOpen={state.transactionsMenu}
            name="Transactions"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/data-transfer_6337624.png"
                  alt="Transactions"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.transactionsMenu
                      ? "rotate(180deg)"
                      : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
            <MenuItemLink
              to="api/payment/dashboard"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Payment Dashboard`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/paymentdashboard.png"
                  alt="Payment Dashboard"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/deposit"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  Deposits
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/deposit.png"
                  alt="Deposit"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/payment/withdrawal"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  Withdrawals
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/withdrawals.png"
                  alt="Withdrawal"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
              title="withdrawals"
            />
            <MenuItemLink
              to="/api/payment/convert"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography sx={{ fontWeight: "bold", color: "#000000" }}>
                  Transfers
                </Typography>
              }
              leftIcon={
                <img
                  src="/images/converts.png"
                  alt="Transfer"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />

            <MenuItemLink
              to="/api/transactions/referral-transaction"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Referral Transactions`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/referraal.png"
                  alt="Referral"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/bonus-transaction"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Bonus Transactions`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/bonus_9587115.png"
                  alt="Bonus"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
            <MenuItemLink
              to="/api/transactions/admin-transaction"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Refund Transactions`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/adminn.png"
                  alt="Admin"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
            <MenuItemLink
              to="/api/payment/tax-deductions"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`TDS Details`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/taxes_4749988.png"
                  alt="TDS"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("couponMenu")}
            isOpen={state.couponMenu}
            name="Coupon"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/coupon.png"
                  alt="Coupon"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.couponMenu
                      ? "rotate(180deg)"
                      : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
            <MenuItemLink
              to="/promo/coupons"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Create Coupons`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/createe.png"
                  alt="Create Tournament"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
            <MenuItemLink
              to="/api/Coupon/coupon-history"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Coupon Dashboard`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/dashboard_7663921.png"
                  alt="Coupon Dashboard"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("GlobalMenu")}
            isOpen={state.GlobalMenu}
            name="Global"
            icon={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/global.png"
                  alt="Global"
                  width="24"
                  height="24"
                />
                <KeyboardArrowDown
                  sx={{
                    ml: 1,
                    transform: state.GlobalMenu
                      ? "rotate(180deg)"
                      : "rotate(0)",
                    transition: "transform 0.2s",
                  }}
                />
              </Box>
            }
            dense={dense}
          >
            <MenuItemLink
              to="/api/users/country-list/dashboard"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Countries`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/country_18615058.png"
                  alt="Country List Dashboard"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
            <MenuItemLink
              to="/payment/conversion-rate"
              state={{ _scrollToTop: true }}
              primaryText={
                <Typography
                  sx={{ fontWeight: "bold", color: "#000000" }}
                >{`Currency Conversion`}</Typography>
              }
              leftIcon={
                <img
                  src="/images/conversion.png"
                  alt="Currency Conversion"
                  width="24"
                  height="24"
                />
              }
              dense={dense}
            />
          </SubMenu>

          <MenuItemLink
            to="/api/tasks/create"
            state={{ _scrollToTop: true }}
            primaryText={
              <Typography
                sx={{ fontWeight: "bold", color: "#000000" }}
              >{`Export Game History`}</Typography>
            }
            leftIcon={
              <img
                src="/images/export_17866826.png"
                alt="Export"
                width="24"
                height="24"
              />
            }
            dense={dense}
          />
        </>
      )}
    </ScrollableBox>
  );
};

export default Menu;
